import Grid from '@mui/material/Grid';
import * as R from 'ramda';
import { useEffect } from 'react';
import { FieldOptions } from '../../hooks/useField';
import { useRefForm } from '../../hooks/useRefForm';
import {
  EscalationTree,
  IncidentHandler,
  IncidentHandlerData,
  Workgroup,
} from '../../types';
import { isValidIncidentHandlerType } from '../../utils/incidentHandler';
import { getReferenceableOptions, isValidRef } from '../../utils/referenceable';

import { required } from '../../utils/validators';
import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { Select, SelectOption } from '../Select';
import { TextField } from '../TextField';

export interface IncidentHandlerFormDialogProps
  extends FormDialogBaseProps<IncidentHandlerData> {
  workgroups: Workgroup[];
  escalationTrees: EscalationTree[];
}

export function IncidentHandlerFormDialog(
  props: IncidentHandlerFormDialogProps,
) {
  const {
    open, onCancel, onSave, workgroups, escalationTrees,
  } = props;

  function requiredIfType<T>(type: IncidentHandler['type']) {
    return (value: T) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (form.state.type.value === type) {
        return required(value);
      }
      return true;
    };
  }

  const form = useRefForm({
    type: {
      value: 'Stub',
      validate: isValidIncidentHandlerType,
    } as FieldOptions<IncidentHandler['type']>,
    workgroup: {
      value: workgroups[0]?.ref ?? '',
      validate: isValidRef(workgroups, 'workgroup'),
    },
    tree: {
      value: escalationTrees[0]?.ref ?? '',
      validate: requiredIfType('SimpleAfterHoursEscalation'),
    },
    description: {
      value: '',
      validate: requiredIfType('Stub'),
    },
  });

  useEffect(() => {
    if (form.state.type.isValidating) {
      form.validate();
    }
  }, [form, form.state.type.value]);

  const typeOptions: SelectOption<IncidentHandler['type']>[] = [
    {
      value: 'Stub',
    },
    {
      value: 'SimpleAfterHoursEscalation',
    },
  ];

  const getValues = () => {
    const data = form.getValues();
    if (data.type === 'Stub') {
      return R.omit(['tree'], data);
    }
    return R.omit(['description'], data);
  };

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([getValues()]);
    }
  };

  const renderTypeDependentSection = () => {
    if (form.state.type.value === 'Stub') {
      return (
        <Grid item xs={12}>
          <TextField label="Description" field={form.state.description} />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <Select
          label="Escalation Tree"
          options={getReferenceableOptions(escalationTrees)}
          field={form.state.tree}
        />
      </Grid>
    );
  };

  return (
    <FormDialog
      title="Add Incident Handler"
      open={open}
      onSave={handleSave}
      onCancel={() => onCancel?.()}
      clearItem={form.clear}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={12}>
          <Select
            label="Workgroup"
            options={getReferenceableOptions(workgroups)}
            field={form.state.workgroup}
          />
        </Grid>
        <Grid item xs={12}>
          <Select label="Type" options={typeOptions} field={form.state.type} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Name" field={form.state.display_name} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Ref" field={form.state.id} />
        </Grid>
        {renderTypeDependentSection()}
      </Grid>
    </FormDialog>
  );
}
