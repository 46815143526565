import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { DataTable, TableSchema } from '../../components/DataTable';
import { UtilityFormDialog } from '../../components/forms/UtilityFormDialog';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import {
  IncidentHandler,
  ServiceArea,
  ShutoffList,
  Utility,
  Workgroup,
} from '../../types';
import { getRefName } from '../../utils/referenceable';

export interface UtilitiesPageProps {
  utilities: ReferenceStore<Utility>;
  workgroups: ReferenceStore<Workgroup>;
  incidentHandlers: ReferenceStore<IncidentHandler>;
  serviceAreas: ReferenceStore<ServiceArea>;
  shutoffLists: ReferenceStore<ShutoffList>;
}
export function UtilitiesPage(props: UtilitiesPageProps) {
  const {
    incidentHandlers,
    serviceAreas,
    shutoffLists,
    utilities,
    workgroups,
  } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<Utility> = {
    workgroup: {
      header: 'Workgroup',
    },
    utility_type: {
      header: 'Utility Type',
    },
    default_handler: {
      header: 'Default Incident Handler',
    },
    service_area: {
      header: 'Service Area',
    },
    shutoff_list: {
      header: 'Shutoff List',
    },
  };

  const mapRefs = (item: Utility): Utility => ({
    ...item,
    workgroup: getRefName(item.workgroup, workgroups),
    default_handler: getRefName(item.default_handler, incidentHandlers),
    service_area:
        item.service_area != null
          ? getRefName(item.service_area, serviceAreas)
          : undefined,
    shutoff_list:
        item.shutoff_list != null
          ? getRefName(item.shutoff_list, shutoffLists)
          : undefined,
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Utilities
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UtilityFormDialog
          utilities={utilities.references}
          incidentHandlers={incidentHandlers.references}
          serviceAreas={serviceAreas.references}
          shutoffLists={shutoffLists.references}
          workgroups={workgroups.references}
          open={isOpen}
          onCancel={() => setOpen(false)}
          onSave={(items) => {
            utilities.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={utilities.references.map(mapRefs)}
          onAdd={() => setOpen(true)}
          onDelete={(item) => utilities.deleteReferences(item)}
          hint="No Utilities Created"
        />
      </Grid>
    </Grid>
  );
}
