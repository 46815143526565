import { useState } from 'react';
import { v4 } from 'uuid';
import { NCInputText, NCSelect } from '@daupler/nexus-components';
import { IncidentEmailProcessorProps, UIIncidentEmailProcessorSouthCarolina811Options } from './resource-form-types';
import { EntityConfigParamType } from '../../types/EntityConfig';
import { InputList } from '../InputList';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function IncidentEmailProcessorSouthCarolina811({
  onChange,
  params,
  value,
  workgroups,
}: IncidentEmailProcessorProps) {
  const {
    workgroupId,
    tzNameId,
    emergencyStatuses,
    emergency_etc_hours: emergencyEtcHours,
    incident_version: incidentVersion,
    category_name_template: categoryNameTemplate,
    target_entity_id: targetEntityId,
  } = (value as UIIncidentEmailProcessorSouthCarolina811Options);

  const [newEmergencyStatus, setNewEmergencyStatus] = useState('');

  return (
    <>
      <NCSelect
        label="Workgroup"
        name="south_carolina_811-workgroup_id"
        options={[
          { label: 'Choose one...', value: '' },
          ...workgroups.map(({ resource, id }) => ({
            label: resource.display_name,
            value: id,
          })),
        ]}
        value={workgroupId}
        onChange={(event) => ({
          ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
          workgroupId: event.target.value,
        })}
      />
      <ConfigVisualEditorParamControl
        label="TZ Name"
        name="south_carolina_811-tzname"
        className="nc-l-mt_200_mobile"
        params={params}
        paramType={EntityConfigParamType.STRING}
        value={tzNameId}
        onChange={(event) => ({
          ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
          tzNameId: event.target.value,
        })}
      />
      <InputList<{ emergencyStatus: string; id: string; }>
        data={(emergencyStatuses ?? [])}
        label="Emergency Statuses"
        onAdd={() => {
          onChange({
            ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
            emergencyStatuses: [
              ...(value as UIIncidentEmailProcessorSouthCarolina811Options).emergencyStatuses,
              { id: v4(), emergencyStatus: newEmergencyStatus },
            ],
          });
        }}
        onRemove={(data) => {
          onChange({
            ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
            emergencyStatuses: data,
          });
        }}
        renderItem={({ emergencyStatus }) => (
          <p>{emergencyStatus}</p>
        )}
        placeholder="No Emergency Statuses here yet!"
      >
        <NCInputText
          name="south_carolina_811-new_emergency_status"
          label="Emergency Status"
          value={newEmergencyStatus}
          onChange={(event) => setNewEmergencyStatus(event.target.value)}
        />
      </InputList>
      <NCInputText
        label="Emergency ETC Hours"
        name="south_carolina_811-emergency_etc_hours"
        type="number"
        step={1}
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
            emergency_etc_hours: parseInt(event.target.value, 10),
          });
        }}
        value={emergencyEtcHours}
      />
      <NCInputText
        label="Incident Version"
        name="south_carolina_811-incident_version"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
            incident_version: event.target.value,
          });
        }}
        value={incidentVersion}
      />
      <NCInputText
        label="Category Name Template"
        name="south_carolina_811-category_name"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
            category_name_template: event.target.value,
          });
        }}
        value={categoryNameTemplate}
      />
      <NCInputText
        label="Target Entity ID"
        name="south_carolina_811-target_entity_id"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorSouthCarolina811Options),
            target_entity_id: event.target.value,
          });
        }}
        value={targetEntityId}
      />
    </>
  );
}
