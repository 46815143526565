import { useRouteError } from 'react-router';
import { UnauthorizedError } from '@daupler/nexus-components';
import { ErrorUnauthorized } from './ErrorUnauthorized';
import { ErrorUnknown } from './ErrorUnknown';

export function ErrorRenderer({ error }: { error?: unknown }) {
  const routeError = useRouteError();

  const resolvedError = routeError ?? error;

  if (resolvedError instanceof UnauthorizedError) {
    return <ErrorUnauthorized />;
  }

  if (resolvedError instanceof Error) {
    return <ErrorUnknown errorMessage={resolvedError.message} />;
  }

  return <ErrorUnknown errorMessage="" />;
}
