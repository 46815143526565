import { NCButton, NCModal } from '@daupler/nexus-components';
import clsx from 'clsx';
import { useState } from 'react';
import { useMatches } from 'react-router';
import { Link } from 'react-router-dom';
import './NavToolPicker.css';

type NavLink = {
  to: string;
  label: string;
  icon: string;
  root?: boolean;
};

export function NavToolPicker() {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [isPicking, setIsPicking] = useState(false);

  const openPicker = () => {
    setIsPickerVisible(true);
    setIsPicking(true);
  };

  const links: NavLink[] = [
    {
      to: '/',
      label: 'YAML Config Tool',
      icon: 'fa-code',
      root: true,
    },
    { to: '/config/entities', label: 'Entity Configurations', icon: 'fa-building' },
  ];

  const matches = useMatches();
  const isLinkActive = (link: NavLink) => {
    const path = matches[matches.length - 1].pathname;
    if (path === '/') { return link.root; }
    if (link.root && path !== '/') { return false; }
    return path.includes(link.to);
  };

  return (
    <>
      <div>
        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          color={NCButton.colors.GREY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={openPicker}
        >
          <i className="fa-solid fa-grid fa-fw" />
        </NCButton>
      </div>
      {isPickerVisible ? (
        <NCModal
          isOpen={isPicking}
          onClose={() => { setIsPicking(false); }}
          onCloseFinished={() => { setIsPickerVisible(false); }}
          closeLabel="Close"
          title="Daupler Configuration Tools"
        >
          <ul className="nav_tool_picker__links">
            {links.map((link) => (
              <li className="nav_tool_picker__links__link" key={link.to}>
                <Link
                  to={link.to}
                  className={clsx('nav_tool_picker__links__link__text', {
                    'nav_tool_picker__links__link__text--active': isLinkActive(link),
                  })}
                >
                  <i className={`fa-solid ${link.icon}`} />
                  {' '}
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </NCModal>
      ) : null}
    </>
  );
}
