import { NCButton, NCDauplerLogo, NCErrorView } from '@daupler/nexus-components';
import { useLocation, useNavigate } from 'react-router';

export function ErrorUnauthorized() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <NCErrorView
      title="Unauthorized"
      message={(
        <>
          <p>Sorry, you are not authorized to view the requested resource.</p>
          <NCButton
            className="nc-l-mt_200_mobile"
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => {
              navigate(`/login?returl=${encodeURIComponent(pathname)}?${encodeURIComponent(search)}`);
            }}
          >
            Log In
          </NCButton>
        </>
      )}
      logo={<NCDauplerLogo />}
    />
  );
}
