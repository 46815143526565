import { NCToast } from '@daupler/nexus-components';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useToast } from '../hooks/useToasts';
import './Toasts.css';

const PORTAL_ID = 'toast';

function Toasts() {
  const {
    toasts,
    removeToast,
  } = useToast();

  useEffect(() => {
    if (document.getElementById(PORTAL_ID)) {
      return;
    }
    const portal = document.createElement('div');
    portal.setAttribute('id', PORTAL_ID);
    document.body.prepend(portal);
  }, []);

  if (!document.getElementById(PORTAL_ID)) { return null; }

  const toast = toasts[toasts.length - 1];

  return createPortal(
    (
      <div className="toasts">
        {toast ? (
          <NCToast
            key={toast.id}
            onCloseAction={() => removeToast(toast.id)}
            title={toast.subject}
            subtitle={toast.body}
            type={toast.type}
          />
        ) : null}
      </div>
    ),
    document.getElementById(PORTAL_ID) as Element,
  );
}

export default Toasts;
