export const and = <T>(
  ...validators: MessageValidator<T>[]
): MessageValidator<T> => (value: T) => validators.reduce((acc, validator) => {
    if (acc === false || typeof acc === 'string') {
      return acc;
    }
    return validator(value);
  }, true as boolean | string);

export const or = <T>(...validators: MessageValidator<T>[]) => (value: T) => validators
  .reduce((acc, validator) => {
    if (acc === true) {
      return acc;
    }
    const result = validator(value);
    if (result === true) {
      return result;
    } if (typeof acc === 'string') {
      return acc;
    }
    return result;
  }, false as boolean | string);

export const required = <T>(value: T) => {
  if (value != null && (typeof value !== 'string' || value !== '') && (!Array.isArray(value) || value.length > 0)) {
    return true;
  }
  return 'Required';
};

export const isIn = <T>(values: T[], type: string) => (value: T) => {
  if (values.includes(value)) {
    return true;
  }
  return `Invalid ${type}, Must be one of ${values.join(', ')}`;
};

export const maxLength = <T extends HasLength>(max: number) => (value: T) => {
  if (value.length > max) {
    return `Length must be less than ${max}`;
  }
  return true;
};

export const isValidPhoneNumber = (value: string) => {
  const regex = /\d{3}-\d{3}-\d{4}/;
  if (regex.test(value)) {
    return true;
  }
  return 'Expected form is XXX-XXX-XXXX';
};

export const isOptional = (value: any) => {
  if (value == null || (typeof value === 'string' && value === '')) {
    return true;
  }
  return false;
};

export type MessageValidator<T> = (value: T) => string | boolean;

export interface HasLength {
  length: number
}
