import { UtilityType } from '../types';
import { isIn } from './validators';

export const utilityTypes: UtilityType[] = [
  'WATER',
  'WATER_METERS',
  'WATER_ADJACENT',
  'SEWER',
  'SEWER_ADJACENT',
  'STREETS',
  'STREETS_ADJACENT',
  'STORMWATER',
  'TRAFFIC_SIGNALS',
  'STREETLIGHTS',
  'SIGNS',
  'TREES',
  'HAZMAT',
  'ELECTRIC',
  'GAS',
  'FIBER',
  'VEHICLES',
  'SOLID_WASTE',
  'BILLING',
  'POLICE',
  'CODE_ENFORCEMENT',
  'FACILITY',
  'MISC',
];

export const isValidUtilityType = isIn(utilityTypes, 'utility type');

export const utilityTypeOptions = utilityTypes.map((type) => ({
  value: type,
}));
