import * as R from 'ramda';
import { useState } from 'react';
import { Referenceable, ReferenceableData } from '../types/Referenceable';

export interface UseReferenceStoreOptions<R> {
  prefix: string
  values?: R[]
  onDelete?: (ref: R[]) => void
}

export const useReferenceStore = <R extends Referenceable>(
  options: UseReferenceStoreOptions<R>,
) => {
  const { onDelete, prefix, values } = options ?? {};

  const initial = values?.reduce((acc, ref) => ({
    ...acc,
    [ref.id]: ref,
  }), {}) ?? {};
  const [references, setReferences] = useState<Record<string, R>>(initial);
  const [idMap, setIdMap] = useState<Record<string, string>>({});

  const idToRef = (id: string) => `${prefix}:${id}`;

  const refToId = (ref: string) => ref.replace(`${prefix}:`, '');

  const addReferences = (
    ...reference: ReferenceableData<R>[]
  ) => {
    const refAdded: Record<string, R> = reference.reduce((acc, item) => {
      setIdMap({
        ...idMap,
        [item.id]: item.display_name,
      });

      return {
        ...acc,
        [item.id]: {
          ...item,
          ref: idToRef(item.id),
        },
      };
    }, {});

    setReferences({
      ...references,
      ...refAdded,
    });
  };

  const updateReferences = (
    ...reference: R[]
  ) => {
    const updatedReferences = reference.reduce((acc, item) => ({
      ...acc,
      [item.id]: item,
    }), {});

    setReferences({
      ...references,
      ...updatedReferences,
    });
  };

  const deleteReferences = (
    ...reference: R[]
  ) => {
    onDelete?.(reference);
    const ids = reference.map((item) => item.id);
    setReferences(R.omit(ids, references));
  };

  const getByRef = (ref: string) => {
    const id = refToId(ref);
    return references[id];
  };

  const nameForMissingRef = (ref: string) => {
    const id = refToId(ref);
    return id;
  };

  return {
    references: Object.values(references),
    rawValues: references,
    addReferences,
    deleteReferences,
    updateReferences,
    getByRef,
    nameForMissingRef,
  };
};

export type ReferenceStore<T extends Referenceable> = ReturnType<typeof useReferenceStore<T>>;
