import Grid from '@mui/material/Grid';
import { DetailsFormState } from '../hooks/useConfigState';
import { regionsForCountry, countries } from '../utils/location';
import { Select } from './Select';
import { timezoneOptions } from '../utils/timezone';
import { partitionSchemeOptions } from '../utils/partitionScheme';
import { TextField } from './TextField';
import { moduleOptions } from '../utils/modules';
import { MultiSelect } from './MultiSelect';

export interface DetailsFormProps {
  form: DetailsFormState;
}

export function DetailsForm(props: DetailsFormProps) {
  const { form } = props;

  const countryOptions = countries().map((country) => ({
    value: country.shortCode,
    name: country.name,
  }));
  const regionOptions = regionsForCountry(form.state.country_code.value).map(
    (region) => ({
      value: region.shortCode ?? '',
      name: region.name,
    }),
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <TextField label="Name" field={form.state.name} />
      </Grid>
      <Grid item sm={4} xs={6}>
        <TextField label="Short Name" field={form.state.short_name} />
      </Grid>
      <Grid item sm={4} xs={6}>
        <Select
          label="Timezone"
          field={form.state.timezone}
          options={timezoneOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          label="Service Description"
          field={form.state.service_description}
        />
      </Grid>
      <Grid item sm={4} xs={6}>
        <Select
          label="Country"
          options={countryOptions}
          field={form.state.country_code}
        />
      </Grid>
      <Grid item sm={4} xs={6}>
        <Select
          label="Region"
          options={regionOptions}
          field={form.state.region_code}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <TextField label="Locality" field={form.state.locality} />
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Partition Scheme"
          options={partitionSchemeOptions}
          field={form.state.partition_scheme}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Forwarding Phone Number"
          field={form.state.forward_calls_to}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          label="Business Hours"
          field={form.state.business_hours_description}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelect
          label="Modules"
          field={form.state.modules}
          options={moduleOptions}
        />
      </Grid>
    </Grid>
  );
}
