import { NCInputText } from '@daupler/nexus-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { getKeyValueFromRRule } from '../../utils/rrule-util';

function InputRRuleAfter({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [count, setCount] = useState(getKeyValueFromRRule(value).COUNT ?? '1');
  const updateCount = (event: ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.COUNT !== count) {
      onChange({ COUNT: count });
    }
  }, [count, onChange, value]);

  return (
    <NCInputText
      className="dl-mt--md"
      name="rrule-end-after"
      label="After N Occurences"
      min={1}
      onChange={updateCount}
      step={1}
      type="number"
      value={count}
    />
  );
}

export default InputRRuleAfter;
