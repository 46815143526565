import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { DataTable, TableSchema } from '../../components/DataTable';
import { IncidentHandlerFormDialog } from '../../components/forms/IncidentHandlerFormDialog';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { EscalationTree, IncidentHandler, Workgroup } from '../../types';
import { incidentHandlerTypes } from '../../utils/incidentHandler';
import { getRefName } from '../../utils/referenceable';

export interface IncidentHandlerPageProps {
  incidentHandlers: ReferenceStore<IncidentHandler>;
  escalationTrees: ReferenceStore<EscalationTree>;
  workgroups: ReferenceStore<Workgroup>;
}

export function IncidentHandlerPage(props: IncidentHandlerPageProps) {
  const { incidentHandlers, escalationTrees, workgroups } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<IncidentHandler> = {
    id: {
      header: 'Ref',
    },
    workgroup: {
      header: 'Workgroup',
    },
    type: {
      header: 'Type',
      renderValue: (value: IncidentHandler['type']) => {
        if (incidentHandlerTypes.includes(value)) {
          return value;
        }
        return <Typography color="error">{value}</Typography>;
      },
    },
    display_name: {
      header: 'Name',
    },
    description: {
      header: 'Description',
    },
    tree: {
      header: 'Escalation Tree',
    },
  };

  const mapRefNames = (incidentHandler: IncidentHandler): IncidentHandler => {
    const mapped = {
      ...incidentHandler,
      workgroup: getRefName(incidentHandler.workgroup, workgroups),
    };

    if (incidentHandler.tree != null) {
      mapped.tree = getRefName(incidentHandler.tree, escalationTrees);
    }

    return mapped;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Incident Handlers
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <IncidentHandlerFormDialog
          open={isOpen}
          escalationTrees={escalationTrees.references}
          workgroups={workgroups.references}
          onSave={(items) => {
            incidentHandlers.addReferences(...items);
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
        />
        <DataTable
          schema={schema}
          data={incidentHandlers.references.map(mapRefNames)}
          onAdd={() => setOpen(true)}
          onDelete={(item) => incidentHandlers.deleteReferences(item)}
          hint="No Incident Handlers Created"
        />
      </Grid>
    </Grid>
  );
}
