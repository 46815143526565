import React from 'react';
import { DauplerApi } from '../lib/daupler-api';
import { AuthenticationContext, useProvideAuthentication } from '../hooks/useAuthentication';

export function ProvideAuthentication({
  api,
  children,
}: React.PropsWithChildren<{ api: DauplerApi }>) {
  const store = useProvideAuthentication({ api });
  return (
    <AuthenticationContext.Provider value={store}>
      {children}
    </AuthenticationContext.Provider>
  );
}
