import { NCButton, NCInputText, NCModal } from '@daupler/nexus-components';
import { useRef, useState } from 'react';
import './ConfigVisualEditorKey.css';
import { clsx } from 'clsx';

type ConfigVisualEditorKeyProps = {
  className?: string;
  keys: [string, number][];
  name: string;
  onAddKey: (key: string) => void;
  onRemoveKey: (key: string) => void;
  onSelectKey: (key: string) => void;
  value: string;
};

export function ConfigVisualEditorKey({
  className,
  keys,
  name,
  onAddKey,
  onRemoveKey,
  onSelectKey,
  value,
}: ConfigVisualEditorKeyProps) {
  const [isEditingKeyVisible, setIsEditingKeyVisible] = useState(false);
  const [isEditingKey, setIsEditingKey] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const editKey = () => {
    setIsEditingKeyVisible(true);
    setIsEditingKey(true);
  };
  const keyListRef = useRef<HTMLDivElement>(null);
  const addKey = () => {
    onAddKey(newKey);
    setNewKey('');
    setTimeout(() => {
      keyListRef.current?.scrollTo({ behavior: 'smooth', top: keyListRef.current.scrollHeight });
    }, 0);
  };
  const closeAddKeyModal = () => {
    setNewKey('');
    setIsEditingKey(false);
  };

  const handleKeySelect = (key: string) => {
    onSelectKey(key);
    closeAddKeyModal();
  };

  return (
    <>
      <NCInputText
        className={className}
        name={name}
        label="Key"
        value={value}
        disabled
        labelAction={(
          <NCButton appearance={NCButton.appearances.LINK} onClick={editKey}>
            Edit
          </NCButton>
        )}
      />

      {isEditingKeyVisible ? (
        <NCModal
          closeLabel="Close"
          isOpen={isEditingKey}
          onClose={closeAddKeyModal}
          onCloseFinished={() => setIsEditingKeyVisible(false)}
          title="Edit Key"
        >
          <NCInputText
            label="Find Key"
            name="key-search"
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
          />
          <div className="config_visual_editor_key__key_list" ref={keyListRef}>
            {keys
              .filter(([key]) => key.toLowerCase().includes(searchFilter.toLowerCase()))
              .map((key) => (
                <div
                  key={key[0]}
                  className={clsx('config_visual_editor_key__key_list__item', {
                    'config_visual_editor_key__key_list__item--selected': key[0] === value,
                  })}
                >
                  <div>
                    <div className="nc-t-sub_text_medium_mobile">{key[0]}</div>
                    <div className="nc-t-info_text_light_mobile">{`Used ${key[1]} ${key[1] === 1 ? 'time' : 'times'}`}</div>
                  </div>
                  <div className="nc-flex nc-flex--gap_1">
                    <NCButton
                      color={key[0] === value ? NCButton.colors.LIGHT : NCButton.colors.PRIMARY}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
                      onClick={() => handleKeySelect(key[0])}
                    >
                      {key[0] === value ? 'Unselect' : 'Select'}
                    </NCButton>
                    <NCButton
                      color={NCButton.colors.ERROR}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
                      onClick={() => onRemoveKey(key[0])}
                      disabled={key[1] > 0}
                    >
                      Remove
                    </NCButton>
                  </div>
                </div>
              ))}
          </div>
          <div className="nc-flex nc-flex--align_end nc-flex--gap_1">
            <NCInputText
              label="New Key"
              name="new-key"
              className="nc-flex_item--1 nc-l-mt_100_mobile"
              value={newKey}
              onChange={(event) => setNewKey(event.target.value)}
            />
            <NCButton
              onClick={addKey}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              appearance={NCButton.appearances.OUTLINE}
              color={NCButton.colors.PRIMARY}
            >
              Add Key
            </NCButton>
          </div>
        </NCModal>
      ) : null}
    </>
  );
}
