import { isIn } from './validators';

const modules = [
  'citizen_engagement',
  'notify',
  'daupler_dispatch',
];

const moduleName = (module: string) => module.split('_').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ');

export const moduleOptions = modules.map((module) => ({
  value: module,
  name: moduleName(module),
}));

export const isValidModules = (values: string[]) => values.reduce((acc, module) => {
  if (acc === false) {
    return acc;
  }
  return isIn(modules, 'module')(module);
}, true as string | boolean);
