import { Grid } from '@mui/material';
import { useRefForm } from '../../hooks/useRefForm';
import { WorkgroupData } from '../../types';

import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { TextField } from '../TextField';

export type WorkgroupFormDialogProps = FormDialogBaseProps<WorkgroupData>;

export function WorkgroupFormDialog(props: WorkgroupFormDialogProps) {
  const form = useRefForm({});

  const { open, onSave, onCancel } = props;

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([form.getValues()]);
    }
  };

  return (
    <FormDialog
      onCancel={() => onCancel?.()}
      open={open}
      onSave={handleSave}
      title="Add Workgroup"
      clearItem={form.clear}
    >
      <Grid container marginTop="4px" spacing={2}>
        <Grid item xs={12}>
          <TextField label="Name" field={form.state.display_name} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Ref" field={form.state.id} />
        </Grid>
      </Grid>
    </FormDialog>
  );
}
