import { NCInputText } from '@daupler/nexus-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { dateToRRuleUntil, getKeyValueFromRRule, rruleUntilToDate } from '../../utils/rrule-util';

function InputRRuleOn({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [date, setDate] = useState(() => {
    const rruleKeyVal = getKeyValueFromRRule(value);
    if (rruleKeyVal.UNTIL) {
      return rruleUntilToDate(rruleKeyVal.UNTIL);
    }
    return new Date();
  });
  const updateDate = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(new Date(event.target.value));
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.UNTIL !== dateToRRuleUntil(date)) {
      onChange({ UNTIL: dateToRRuleUntil(date) });
    }
  }, [date, onChange, value]);

  return (
    <NCInputText
      className="dl-mt--md"
      name="rrule-end-on"
      label="On Date"
      onChange={updateDate}
      type="date"
      value={date.toISOString().split('T')[0]}
    />
  );
}

export default InputRRuleOn;
