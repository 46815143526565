import { CciDispatchRuleProps } from './resource-form-types';
import { CciNeverDispatchParams, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function CciDispatchRuleFormNeverDispatch({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _message_ref: messageRef } = (value ?? {}) as CciNeverDispatchParams;
  return (
    <ConfigVisualEditorParamControl
      label="Message"
      className="nc-l-mt_200_mobile"
      name="never_dispatch-message"
      params={params}
      paramType={EntityConfigParamType.STRING}
      onChange={(event) => onChange({
        ...(value as CciNeverDispatchParams),
        _message_ref: {
          key: event.target.value,
          type: EntityConfigParamType.STRING,
        },
      })}
      value={messageRef?.key ?? ''}
    />
  );
}
