import { config } from '../config';
import { DEFAULT_MAP_ZOOM, latLngPathToPointArray, pointArraytoLatLngPath } from '../utils/location-tools';
import { simplifyLL } from '../utils/simplify';

interface MapDimensions {
  width: number;
  height: number;
}

const DEFAULT_DIMENSIONS = {
  width: 68,
  height: 68,
};

export function ImageStaticMap({
  className,
  lat,
  lng,
  dims,
  paths,
  zoom,
}: {
  className?: string;
  lat: number;
  lng: number;
  dims?: MapDimensions;
  paths?: [number, number][][];
  zoom?: number;
}) {
  const getWidth = () => dims?.width ?? DEFAULT_DIMENSIONS.width;
  const getHeight = () => dims?.height ?? DEFAULT_DIMENSIONS.height;

  const simplifyPath = (
    path: [number, number][],
  ) => latLngPathToPointArray(simplifyLL(pointArraytoLatLngPath(path), 0.001));

  const getFirstPointAsString = (path: [number, number][]) => `${path[0][1]},${path[0][0]}`;

  const closePath = (path: [number, number][], pointStringArray: string[]) => [
    ...pointStringArray,
    getFirstPointAsString(path),
  ];

  const pathToPointStringArray = (path: [number, number][]) => path.map((latLng) => `${latLng[1]},${latLng[0]}`);

  const buildPaths = () => {
    if (!paths?.length) { return ''; }
    return paths.map((path) => {
      if (path.length > 5) {
        const simplifiedPath = simplifyPath(path);
        const stringPath = pathToPointStringArray(simplifiedPath);
        const closedPath = closePath(simplifiedPath, stringPath);
        return `&path=color:0x1f2024ff|fillcolor:0x85858533|weight:4|${closedPath.join('|')}`;
      }
      return `&path=color:0x1f2024ff|fillcolor:0x85858533|weight:4|${path.join('|')}`;
    }).join('');
  };

  const buildUrl = () => {
    const apiKey = config.googleMapsApiKey;
    let url = 'https://maps.googleapis.com/maps/api/staticmap?';
    url += `center=${lat},${lng}`;
    url += `&zoom=${zoom ?? DEFAULT_MAP_ZOOM}`;
    url += `&size=${getWidth()}x${getHeight()}`;
    url += '&maptype=roadmap';
    url += '&scale=2';
    url += '&style=feature:poi%7Cvisibility:off';
    url += buildPaths();
    url += `&key=${apiKey}`;
    return url;
  };

  return (
    <img
      alt=""
      className={className}
      src={buildUrl()}
      height={getHeight()}
      width={getWidth()}
    />
  );
}
