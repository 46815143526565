import { NCSelect } from '@daupler/nexus-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { getKeyValueFromRRule } from '../../utils/rrule-util';

function InputRRuleMonthlyMonthDay({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [monthDay, setMonthDay] = useState(getKeyValueFromRRule(value).BYMONTHDAY ?? '1');
  const updateMonthDay = (event: ChangeEvent<HTMLSelectElement>) => {
    setMonthDay(event.target.value);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.BYMONTHDAY !== monthDay) {
      onChange({ BYMONTHDAY: monthDay });
    }
  }, [monthDay, onChange, value]);

  return (
    <NCSelect
      className="dl-mt--md"
      name="rrule-monthly-monthday"
      label="Day"
      onChange={updateMonthDay}
      options={new Array(31).fill(undefined).map((_, i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString(),
      }))}
      value={monthDay}
    />
  );
}

export default InputRRuleMonthlyMonthDay;
