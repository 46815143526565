import { Button, Grid } from '@mui/material';
import { ChangeEventHandler, useRef } from 'react';
import UploadIcon from '@mui/icons-material/UploadFile';
import { useRefForm } from '../../hooks/useRefForm';
import { ServiceAreaData } from '../../types';

import { required } from '../../utils/validators';
import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { TextField } from '../TextField';

export type ServiceAreaFormDialogProps = FormDialogBaseProps<ServiceAreaData>;

export function ServiceAreaFormDialog(props: ServiceAreaFormDialogProps) {
  const form = useRefForm({
    filename: {
      value: '',
      validate: required,
    },
  });

  const { open, onSave, onCancel } = props;

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([form.getValues()]);
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const handleOnUpload = () => {
    inputFile.current?.click();
  };
  const handleFileChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files[0];
      form.state.filename.onChange(file.name);
    }
  };

  return (
    <FormDialog
      onCancel={() => onCancel?.()}
      open={open}
      onSave={handleSave}
      title="Add Service Area"
      clearItem={form.clear}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={12}>
          <TextField label="Name" field={form.state.display_name} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Ref" field={form.state.id} />
        </Grid>
        <Grid item xs={8}>
          <TextField label="Filename" field={form.state.filename} />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="secondary" fullWidth style={{ height: '100%' }} startIcon={<UploadIcon />} onClick={handleOnUpload}>Choose File</Button>
        </Grid>

      </Grid>
      <input
        type="file"
        id="fileName"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleFileChanged}
      />
    </FormDialog>
  );
}
