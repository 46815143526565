import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { WorkgroupFormDialog } from '../../components/forms/WorgroupFormDialog';
import { DataTable, TableSchema } from '../../components/DataTable';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { Workgroup } from '../../types';

export interface WorkgroupPageProps {
  workgroups: ReferenceStore<Workgroup>;
}

export function WorkgroupPage(props: WorkgroupPageProps) {
  const { workgroups } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<Workgroup> = {
    id: {
      header: 'Ref',
    },
    display_name: {
      header: 'Name',
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Workgroups
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <WorkgroupFormDialog
          open={isOpen}
          onCancel={() => setOpen(false)}
          onSave={(items) => {
            workgroups.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={workgroups.references}
          hint="No Workgroups Created"
          onAdd={() => setOpen(true)}
          onDelete={(item) => workgroups.deleteReferences(item)}
        />
      </Grid>
    </Grid>
  );
}
