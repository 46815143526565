import './Navbar.css';
import { NavToolPicker } from './NavToolPicker';

export function Navbar({
  title,
}: { title: string }) {
  return (
    <nav className="navbar">
      <NavToolPicker />
      <p className="nc-t-h5_medium_mobile">{title}</p>
    </nav>
  );
}
