import { lazy } from './lazy';
import { regionData } from './regionData';

export const countries = lazy(() => regionData.map((country) => ({
  shortCode: country.countryShortCode,
  name: country.countryName,
})));

export const regionsForCountry = (code: string) => {
  const country = regionData.find((region) => region.countryShortCode === code);
  if (country == null) {
    return [];
  }

  return country.regions.map((region) => ({
    name: region.name,
    shortCode: region.shortCode ?? '',
  }));
};
