import {
  FormField,
  NCInputText,
} from '@daupler/nexus-components';
import { EditorEntityResource } from '../../hooks/useEntityConfigEditor';
import { EntityConfigRef, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

type ResourceBaseFormProps = {
  formState: {
    key: FormField<string>;
    displayName: FormField<string>;
    workgroupRef: FormField<EntityConfigRef | undefined>;
  };
  onChange: (name: string, value: string | EntityConfigRef) => void;
  resourceType: string;
  workgroups: EditorEntityResource[];
  entityId: string;
};

export function ResourceBaseForm({
  formState,
  onChange,
  resourceType,
  workgroups,
  entityId,
}: ResourceBaseFormProps) {
  return (
    <>
      <NCInputText
        label="Resource Type"
        name="resource-type"
        value={resourceType}
        disabled
      />
      <NCInputText
        label="Key"
        className="nc-l-mt_200_mobile"
        name={formState.key.name}
        onChange={(event) => onChange(formState.key.name, event.target.value)}
        value={formState.key.value}
        required
        disabled
      />
      <NCInputText
        label="Display Name"
        name={formState.displayName.name}
        onChange={(event) => onChange(formState.displayName.name, event.target.value)}
        value={formState.displayName.value}
        className="nc-l-mt_200_mobile"
        required
      />
      <ConfigVisualEditorResourceRefControl
        label="Workgroup"
        entityId={entityId}
        resources={workgroups}
        resourceType={EntityConfigResourceType.WORKGROUPS}
        name={formState.workgroupRef.name}
        onChange={(event) => onChange(
          formState.workgroupRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.WORKGROUPS,
          },
        )}
        value={formState.workgroupRef.value?.key ?? ''}
        className="nc-l-mt_200_mobile"
      />
    </>
  );
}
