import { NCInputText } from '@daupler/nexus-components';

type Value = { display_name: string; phone_number: string; };

type ConfigVisualEditorPhoneFieldProps = {
  name: string;
  onChange?: (value: Value) => void;
  value: Value;
};

export function ConfigVisualEditorFieldPhone({
  name,
  onChange,
  value,
}: ConfigVisualEditorPhoneFieldProps) {
  const handleChange = (field: string, v: string) => {
    onChange?.({
      ...value,
      [field]: v,
    });
  };

  return (
    <>
      <NCInputText
        label="Display Name"
        name={`${name}-display_name`}
        type="tel"
        onChange={(event) => handleChange('display_name', event.target.value)}
        value={value.display_name}
      />
      <NCInputText
        className="nc-l-mt_200_mobile"
        tag={<span>🇺🇸 +1</span>}
        label="Phone"
        name={`${name}-phone`}
        type="tel"
        onChange={(event) => handleChange('phone_number', event.target.value)}
        value={value.phone_number}
        placeholder="XXX–XXX–XXXX"
        title="3-digit area code, then 7-digit local number"
      />
    </>
  );
}
