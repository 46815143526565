import {
  NCButton,
  NCModal,
  NCTable,
  NCTableBody,
  NCTableHeader,
  NCTableHeaderCol,
  NCTableRow,
  NCTableRowCol,
  timestampShortDisplayString,
} from '@daupler/nexus-components';
import { EditorStoredEntityConfig } from '../hooks/useEntityConfigEditor';
import { EntityConfig } from '../types/EntityConfig';

type ConfigVisualEditorLoadDataModalProps = {
  data: EditorStoredEntityConfig[];
  isOpen: boolean;
  onClose: () => void;
  onCloseFinished: () => void;
  onDownload: (config: EditorStoredEntityConfig) => void;
  onLoad: (config: EntityConfig) => void;
  onSave: () => void;
};

export function ConfigVisualEditorLoadDataModal({
  data,
  isOpen,
  onClose,
  onCloseFinished,
  onDownload,
  onLoad,
  onSave,
}: ConfigVisualEditorLoadDataModalProps) {
  return (
    <NCModal
      closeLabel="Close"
      isOpen={isOpen}
      onClose={onClose}
      title="Manage Entity Data"
      onCloseFinished={onCloseFinished}
      footerChildren={(
        <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-pa_utilities_225_mobile">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onClose}
          >
            Cancel
          </NCButton>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onSave}
          >
            Save New Version
          </NCButton>
        </div>
      )}
    >
      {data.length ? (
        <NCTable>
          <NCTableHeader>
            <NCTableHeaderCol>Version</NCTableHeaderCol>
            <NCTableHeaderCol>Saved</NCTableHeaderCol>
            <NCTableHeaderCol>{' '}</NCTableHeaderCol>
            <NCTableHeaderCol>{' '}</NCTableHeaderCol>
          </NCTableHeader>
          <NCTableBody>
            {data.map((storedConfig, i) => (
              <NCTableRow key={storedConfig.updated}>
                <NCTableRowCol>{i + 1}</NCTableRowCol>
                <NCTableRowCol>{timestampShortDisplayString('en-US', storedConfig.updated)}</NCTableRowCol>
                <NCTableRowCol>
                  <NCButton
                    appearance={NCButton.appearances.SOLID}
                    color={NCButton.colors.GREY}
                    size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
                    width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                    onClick={() => onLoad(storedConfig.config)}
                  >
                    Load
                  </NCButton>
                </NCTableRowCol>
                <NCTableRowCol>
                  <NCButton
                    appearance={NCButton.appearances.SOLID}
                    color={NCButton.colors.GREY}
                    size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
                    width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                    onClick={() => onDownload(storedConfig)}
                  >
                    <i className="fa-solid fa-download" />
                  </NCButton>
                </NCTableRowCol>
              </NCTableRow>
            ))}
          </NCTableBody>
        </NCTable>
      ) : (
        <p className="nc-t-grey_700 nc-t-center nc-t-body_light nc-l-pa_200_mobile">
          No configurations saved for this Entity!
        </p>
      )}
    </NCModal>
  );
}
