import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { DataTable, TableSchema } from '../../components/DataTable';
import { FacilityPolicyFormDialog } from '../../components/forms/FacilityPolicyFormDialog';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { FacilityPolicy, IncidentHandler, Workgroup } from '../../types';
import { getRefName } from '../../utils/referenceable';

export interface FacilityPolicyPageProps {
  workgroups: ReferenceStore<Workgroup>;
  incidentHandlers: ReferenceStore<IncidentHandler>;
  facilityPolicies: ReferenceStore<FacilityPolicy>;
}

export function FacilityPolicyPage(props: FacilityPolicyPageProps) {
  const { workgroups, incidentHandlers, facilityPolicies } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<FacilityPolicy> = {
    id: {
      header: 'Ref',
    },
    workgroup: {
      header: 'Workgroup',
    },
    display_name: {
      header: 'Name',
    },
    handler: {
      header: 'Incident Handler',
    },
  };

  const mapRefs = (item: FacilityPolicy): FacilityPolicy => ({
    ...item,
    workgroup: getRefName(item.workgroup, workgroups),
    handler: getRefName(item.handler, incidentHandlers),
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Facility Policies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FacilityPolicyFormDialog
          open={isOpen}
          incidentHandlers={incidentHandlers.references}
          workgroups={workgroups.references}
          onCancel={() => setOpen(false)}
          onSave={(items) => {
            facilityPolicies.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={facilityPolicies.references.map(mapRefs)}
          onAdd={() => setOpen(true)}
          onDelete={(item) => facilityPolicies.deleteReferences(item)}
          hint="No Facility Policies Created"
        />
      </Grid>
    </Grid>
  );
}
