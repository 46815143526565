import {
  FormField,
  NCButton,
  NCInputText,
  NCLoadingIndicator,
  useForm,
} from '@daupler/nexus-components';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { nonEmptyRegex } from '../utils/validation';
import './LoginRoute.css';
import { useAuthentication } from '../hooks/useAuthentication';

export function LoginRoute() {
  const email: FormField<string> = {
    name: 'email',
    validate: (value) => nonEmptyRegex.test(value),
    value: '',
    invalidMessage: 'Email is required',
    validMessage: '',
  };
  const password: FormField<string> = {
    name: 'password',
    validate: (value) => nonEmptyRegex.test(value),
    value: '',
    invalidMessage: 'Password is required',
    validMessage: '',
  };
  const {
    formState,
    onChange,
    isFormValid,
    getFormValues,
    validateForm,
  } = useForm({ email, password });

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [serverError, setServerError] = useState('');
  const { login } = useAuthentication();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    validateForm();
    if (!isFormValid()) { return; }
    try {
      setIsAuthenticating(true);
      setServerError('');
      const values = getFormValues();
      await login(values.email, values.password);
      if (searchParams.get('returl')) {
        navigate(decodeURIComponent(searchParams.get('returl') as string));
      } else {
        navigate('/config/entities');
      }
    } catch (err) {
      setServerError((err as Error).message);
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <div className="login_route">
      <h1 className="nc-t-h1_medium_mobile nc-t-grey_900">Authenticate</h1>
      <p className="nc-t-body_regular_mobile nc-t-grey_600">Please provide your Daupler staff user credentials to authenticate.</p>

      <form onSubmit={handleSubmit} className="login_route__form">
        <p className="nc-t-body_regular nc-t-error">{serverError}</p>

        <NCInputText
          className={serverError ? 'nc-l-mt_200_mobile' : ''}
          label="Email Address"
          type="email"
          name={formState.email.name}
          isValid={formState.email.isValid}
          autoComplete="email"
          onChange={(event) => onChange(formState.email.name, event.target.value)}
          value={formState.email.value}
          hint={formState.email.message}
        />

        <NCInputText
          className="nc-l-mt_200_mobile"
          label="Password"
          type="password"
          name={formState.password.name}
          isValid={formState.password.isValid}
          onChange={(event) => onChange(formState.password.name, event.target.value)}
          value={formState.password.value}
          hint={formState.password.message}
        />

        <NCButton
          className="nc-l-mt_200_mobile"
          color={NCButton.colors.PRIMARY}
          type="submit"
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          disabled={isAuthenticating}
        >
          {isAuthenticating ? 'Authenticating...' : 'Log In'}
        </NCButton>

        {isAuthenticating ? <NCLoadingIndicator blockUi className="login_route__loading" label="Authenticating..." /> : null}
      </form>
    </div>
  );
}
