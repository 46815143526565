import { ChangeEvent, useEffect, useState } from 'react';
import { NCInputRadioGroup, NCInputText } from '@daupler/nexus-components';
import { MonthlyRecurrenceType, RRuleKeyValue } from './InputRRule.d';
import InputRRuleMonthlyDay from './InputRRuleMonthlyDay';
import InputRRuleMonthlyMonthDay from './InputRRuleMonthlyMonthDay';
import { getKeyValueFromRRule } from '../../utils/rrule-util';

const RecurrenceComponentMap = {
  [MonthlyRecurrenceType.DAY]: InputRRuleMonthlyDay,
  [MonthlyRecurrenceType.MONTHDAY]: InputRRuleMonthlyMonthDay,
};

function InputRRuleMonthly({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [type, setType] = useState<MonthlyRecurrenceType>(() => {
    const rruleKeyVal = getKeyValueFromRRule(value);
    if (rruleKeyVal.BYMONTHDAY) {
      return MonthlyRecurrenceType.MONTHDAY;
    }
    if (rruleKeyVal.BYSETPOS) {
      return MonthlyRecurrenceType.DAY;
    }
    return MonthlyRecurrenceType.MONTHDAY;
  });
  const updateType = (event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as MonthlyRecurrenceType);
  };

  const [recurrenceInterval, setRecurrenceInterval] = useState(getKeyValueFromRRule(value).INTERVAL ?? '1');
  const updateRecurrenceInterval = (event: ChangeEvent<HTMLInputElement>) => {
    setRecurrenceInterval(event.target.value);
  };

  const [recurrenceData, setRecurrenceData] = useState<RRuleKeyValue>(getKeyValueFromRRule(value));
  const handleRecurrenceChange = (keyValue: Record<string, string>) => {
    setRecurrenceData(keyValue);
    onChange(keyValue);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.INTERVAL !== recurrenceInterval) {
      onChange({
        INTERVAL: recurrenceInterval,
        ...recurrenceData,
      });
    }
  }, [recurrenceInterval, recurrenceData, onChange, value]);

  const RecurrenceComponent = RecurrenceComponentMap[type];

  return (
    <>
      <NCInputText
        className="dl-mt--md"
        name="rrule-monthly-recurrency-interval"
        label="Every N Months"
        min={1}
        onChange={updateRecurrenceInterval}
        step={1}
        type="number"
        value={recurrenceInterval}
      />

      <div className="d-input__radiogroup dl-mt--md">
        <NCInputRadioGroup
          value={value}
          label="Recurrence Type"
          name="rrule-monthly-recurrence-type"
          onChange={updateType}
          options={[
            {
              id: MonthlyRecurrenceType.MONTHDAY,
              label: 'On Day',
            },
            {
              id: MonthlyRecurrenceType.DAY,
              label: 'On The',
            },
          ]}
        />
      </div>

      <RecurrenceComponent onChange={handleRecurrenceChange} value={value} />
    </>
  );
}

export default InputRRuleMonthly;
