import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { DesignationsFormDialog } from '../../components/forms/DesignationsFormDialog';
import { DataTable, TableSchema } from '../../components/DataTable';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { Designation, Workgroup } from '../../types';
import { getRefName } from '../../utils/referenceable';

export interface DesignationPageProps {
  workgroups: ReferenceStore<Workgroup>;
  designations: ReferenceStore<Designation>;
}

export function DesignationPage(props: DesignationPageProps) {
  const { designations, workgroups } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<Designation> = {
    id: {
      header: 'Ref',
    },
    workgroup: {
      header: 'Workgroup',
    },
    display_name: {
      header: 'Name',
    },
    shared_phone_number: {
      header: 'Shared Phone',
    },
  };

  const mapWorkgroupRef = (designation: Designation) => ({
    ...designation,
    workgroup: getRefName(designation.workgroup, workgroups),
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography align="left" variant="h3">
          Designations
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DesignationsFormDialog
          open={isOpen}
          workgroups={workgroups.references}
          onCancel={() => setOpen(false)}
          onSave={(items) => {
            designations.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={designations.references.map(mapWorkgroupRef)}
          onAdd={() => setOpen(true)}
          onDelete={(item) => designations.deleteReferences(item)}
          hint="No Designations Created"
        />
      </Grid>
    </Grid>
  );
}
