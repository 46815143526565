export const lazy = <A extends any[], R>(operation: Operation<A, R>) => {
  const instance: ValueHolder<R> = {};

  return (...args: A) => {
    if (!Object.prototype.hasOwnProperty.call(instance, 'value')) {
      instance.value = operation(...args);
    }

    return instance.value as R;
  };
};

export type Operation<A extends any[], R> = (...args: A) => R;

export type ValueHolder<T> = {
  value?: T;
};
