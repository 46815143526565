import { NCButton, NCInputText } from '@daupler/nexus-components';
import './ConfigVisualEditorObjectsMenu.css';
import { useState } from 'react';
import { EntityConfigParam } from '../types/EntityConfig';
import { ConfigVisualEditorField } from './ConfigVisualEditorField';
import { ConfigVisualEditorParamForm } from './ConfigVisualEditorParamForm';
import { SegmentedControl } from './SegmentedControl';
import { EditorEntityParam } from '../hooks/useEntityConfigEditor';

enum View {
  PARAM_ADD = 'PARAM_ADD',
  PARAM_EDIT = 'PARAM_EDIT',
  PARAMS = 'PARAMS',
  KEYS = 'KEYS',
}

type ConfigVisualEditorObjectsMenuProps = {
  keys: [string, number][];
  params: [EditorEntityParam, number][];
  onKeyAdd: (key: string) => void;
  onKeyFilter: (key: string) => void;
  onKeyRemove: (key: string) => void;
  onParamAdd: (param: EntityConfigParam) => void;
  onParamFilter: (paramKey: string) => void;
  onParamRemove: (id: string) => void;
  onParamUpdate: (id: string, param: EntityConfigParam) => void;
};

export function ConfigVisualEditorObjectsMenu({
  keys,
  params,
  onKeyAdd,
  onKeyFilter,
  onKeyRemove,
  onParamAdd,
  onParamFilter,
  onParamRemove,
  onParamUpdate,
}: ConfigVisualEditorObjectsMenuProps) {
  const [view, setView] = useState(View.PARAMS);
  const [searchFilter, setSearchFilter] = useState('');

  const [paramToEdit, setParamToEdit] = useState<EditorEntityParam | null>(null);

  return (
    <div className="config_visual_editor_objects_menu">
      <div className="config_visual_editor_objects_menu__header">
        {view === View.PARAM_ADD || view === View.PARAM_EDIT ? (
          <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
            <NCButton
              color={NCButton.colors.LIGHT}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              onClick={() => {
                setView(View.PARAMS);
                setParamToEdit(null);
              }}
            >
              <i className="fa-solid fa-arrow-left" />
            </NCButton>
            <p className="nc-t-h4_medium_mobile">
              {view === View.PARAM_ADD ? 'Add Param' : 'Edit Param'}
            </p>
          </div>
        ) : (
          <>
            <SegmentedControl
              segments={[
                { label: 'Params', value: View.PARAMS },
                { label: 'Keys', value: View.KEYS },
              ]}
              value={view}
              onChange={(value) => setView(value as View)}
            />
            <NCInputText
              name="search"
              label="Search"
              labelHidden
              className="nc-l-mt_200_mobile"
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              tag={(<i className="fa-solid fa-search" />)}
              button={searchFilter ? {
                label: (<i className="fa-solid fa-fw fa-circle-x nc-t-grey_500" />),
                onClick: () => { setSearchFilter(''); },
              } : undefined}
            />
          </>
        )}
      </div>

      <div className="config_visual_editor_objects_menu__body">
        {view === View.PARAMS ? params
          .filter((param) => (searchFilter
            ? JSON.stringify(param).toLowerCase().includes(searchFilter)
            : true))
          .map(([param, usages]) => (
            <div key={param.param.key} className="config_visual_editor_objects_menu__item">
              <p>
                <span className="nc-t-info_text_medium_mobile config_visual_editor_objects_menu__item__key">
                  {param.param.key}
                </span>
                <br />
                <span className="nc-t-body_light_mobile">
                  {param.param.description}
                </span>
              </p>
              <div className="nc-l-mt_100_mobile">
                <ConfigVisualEditorField
                  type={param.param.type}
                  fieldValue={param.param.value}
                  fieldName=""
                  id={param.param.key}
                  preview
                />
              </div>
              <p className="nc-l-mt_utilities_50_mobile nc-t-sub_text_regular_mobile nc-t-grey_7  00">
                Used
                {' '}
                <NCButton
                  appearance={NCButton.appearances.LINK}
                  color={NCButton.colors.PRIMARY}
                  onClick={() => onParamFilter(param.param.key)}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                >
                  {`${usages} ${usages === 1 ? 'time' : 'times'}`}
                </NCButton>
                .
              </p>
              <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_200_mobile">
                <NCButton
                  appearance={NCButton.appearances.LINK}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  onClick={() => {
                    setParamToEdit(param);
                    setView(View.PARAM_EDIT);
                  }}
                >
                  Edit
                </NCButton>
                <NCButton
                  appearance={NCButton.appearances.OUTLINE}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  color={NCButton.colors.ERROR}
                  size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
                  disabled={usages !== 0}
                  onClick={() => onParamRemove(param.id)}
                >
                  Delete
                </NCButton>
              </div>
            </div>
          )) : null}
        {view === View.KEYS ? keys
          .filter((key) => (searchFilter
            ? JSON.stringify(key).toLowerCase().includes(searchFilter)
            : true))
          .map(([key, usages]) => (
            <div key={key} className="config_visual_editor_objects_menu__item">
              <p>
                <span className="nc-t-info_text_medium_mobile config_visual_editor_objects_menu__item__key">
                  {key}
                </span>
              </p>
              <p className="nc-l-mt_utilities_50_mobile nc-t-sub_text_regular_mobile nc-t-grey_7  00">
                Used
                {' '}
                <NCButton
                  appearance={NCButton.appearances.LINK}
                  color={NCButton.colors.PRIMARY}
                  onClick={() => onKeyFilter(key)}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                >
                  {`${usages} ${usages === 1 ? 'time' : 'times'}`}
                </NCButton>
                .
              </p>
              <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_200_mobile">
                <div />
                <NCButton
                  appearance={NCButton.appearances.OUTLINE}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  color={NCButton.colors.ERROR}
                  size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
                  disabled
                >
                  Delete
                </NCButton>
              </div>
            </div>
          )) : null}
        {view === View.PARAM_EDIT && paramToEdit ? (
          <ConfigVisualEditorParamForm
            description={paramToEdit?.param.description ?? ''}
            keys={keys}
            onAddKey={onKeyAdd}
            onRemoveKey={onKeyRemove}
            onSubmit={(param) => {
              onParamUpdate(paramToEdit.id, param);
              setView(View.PARAMS);
            }}
            paramKey={paramToEdit.param.key ?? ''}
            type={paramToEdit.param.type ?? ''}
            value={paramToEdit.param.value}
          />
        ) : null}
        {view === View.PARAM_ADD ? (
          <ConfigVisualEditorParamForm
            keys={keys}
            onAddKey={onKeyAdd}
            onRemoveKey={onKeyRemove}
            onSubmit={(param) => {
              onParamAdd(param);
              setView(View.PARAMS);
            }}
          />
        ) : null}
      </div>

      {view === View.PARAMS ? (
        <div className="config_visual_editor_objects_menu__action">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.PRIMARY}
            onClick={() => setView(View.PARAM_ADD)}
          >
            Add Param
          </NCButton>
        </div>
      ) : null}
    </div>
  );
}
