import { useState } from 'react';
import { generateId } from '../utils/referenceable';
import { required } from '../utils/validators';
import { Form, FormRecord, useForm } from './useForm';

export const useRefForm = <R extends FormRecord>(form: Form<R>) => {
  const [isGenerating, setIsGenerating] = useState(true);

  const formInstance = useForm({
    id: {
      value: '',
      validate: required,
      onChange: () => {
        setIsGenerating(false);
      },
    },
    display_name: {
      value: '',
      validate: required,
      onChange: (value: string) => {
        if (isGenerating) {
          formInstance.state.id.onChange.private(generateId(value));
        }
      },
    },
    ...form,
  });

  const original = formInstance.clear;
  formInstance.clear = () => {
    original();
    setIsGenerating(true);
  };

  return formInstance;
};
