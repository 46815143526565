import { NCSelect } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciDispatchStandardRuleType, CciStandardDispatchRuleParams } from '../../types/EntityConfig';

export function CciDispatchRuleFormStandardRule({
  onChange,
  value,
}: CciDispatchRuleProps) {
  const {
    rule_type: ruleType,
  } = (value ?? {}) as CciStandardDispatchRuleParams;

  return (
    <NCSelect
      label="Rule Type"
      name="standard_rule-type"
      options={[
        { label: 'Choose one...', value: '' },
        ...Object.values(CciDispatchStandardRuleType)
          .map((type) => ({
            label: type,
            value: type,
          })),
      ]}
      onChange={(event) => onChange({
        ...(value as CciStandardDispatchRuleParams),
        rule_type: event.target.value as CciDispatchStandardRuleType,
      })}
      value={ruleType}
    />
  );
}
