import { FormHelperText } from '@mui/material';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MUISelect, {
  SelectChangeEvent,
  SelectProps as MUISelectProps,
} from '@mui/material/Select';
import { useRef } from 'react';
import { FieldState } from '../hooks/useField';
import { SelectOption } from './Select';

export type MultiSelectProps<T extends string | number> = {
  options: SelectOption<T>[];
  label: string;
  helperText?: string;
  field?: FieldState<T[]>;
} & MUISelectProps<T[]>;

export function MultiSelect<T extends string | number>(
  props: MultiSelectProps<T>,
) {
  const {
    options,
    label,
    helperText,
    value,
    onChange,
    error,
    field,
    ...parentProps
  } = props;
  const labelId = useRef(Math.random().toString());
  const nameForSelected = (valueToFind: T) => options
    .find((item) => item.value === valueToFind)?.name ?? value;

  const resolvedError = error ?? !(field?.isValid ?? true);
  const resolvedHelperText = helperText ?? field?.invalidMessage;

  const handleChange = (
    event: SelectChangeEvent<T[]>,
    child: React.ReactNode,
  ) => {
    if (onChange != null) {
      onChange(event, child);
    } else if (field != null) {
      field.onChange(event.target.value as T[]);
    }
  };

  return (
    <FormControl fullWidth error={resolvedError}>
      <InputLabel id={labelId.current}>{label}</InputLabel>
      <MUISelect<T[]>
        multiple
        fullWidth
        labelId={labelId.current}
        label={label}
        error={resolvedError}
        value={value ?? field?.value}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...parentProps}
        renderValue={(selected) => (
          <Grid container spacing={1} key="hello">
            {selected.map((selectedValue) => (
              <Grid key={selectedValue} item>
                <Chip key={selectedValue} label={nameForSelected(selectedValue)} />
              </Grid>
            ))}
          </Grid>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </MUISelect>
      {resolvedHelperText && resolvedHelperText !== null && resolvedHelperText !== '' ? (
        <FormHelperText>{resolvedHelperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
