import { useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';

export function EscalationTreeForm({
  entityId,
  onChange,
  onValidate,
  resourceData,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      data: {},
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
    },
  });

  return (
    <ResourceBaseForm
      entityId={entityId}
      formState={formState}
      onChange={onFormChange}
      resourceType={resourceType}
      workgroups={workgroups}
    />
  );
}
