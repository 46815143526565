import Grid from '@mui/material/Grid';
import { FieldOptions, FieldState } from '../../hooks/useField';
import { useForm } from '../../hooks/useForm';
import {
  Designation,
  DesignationProfile,
  PersonalPhonePolicy,
} from '../../types';
import {
  personalPhonePolicyOptions,
  isValidPersonalPhonePolicy,
} from '../../utils/escalationTree';
import { getReferenceableOptions, isValidRef } from '../../utils/referenceable';

import { required } from '../../utils/validators';
import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { Select } from '../Select';
import { TextField } from '../TextField';

export interface DesignationProfileFormDialogProps
  extends FormDialogBaseProps<DesignationProfile> {
  designations: Designation[];
}

export function DesignationProfileFormDialog(
  props: DesignationProfileFormDialogProps,
) {
  const {
    designations, open, onSave, onCancel,
  } = props;

  const form = useForm({
    designation: {
      value: designations[0]?.ref ?? '',
      validate: isValidRef(designations, 'designation'),
    },
    start_minutes: {
      value: 0,
      validate: required,
    },
    personal_phone_policy: {
      value: 'NONE',
      validate: isValidPersonalPhonePolicy,
    } as FieldOptions<PersonalPhonePolicy>,
  });

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([form.getValues()]);
    }
  };

  return (
    <FormDialog
      title="Add Designation Profile"
      open={open}
      onSave={handleSave}
      onCancel={() => onCancel?.()}
      clearItem={form.clear}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={12}>
          <Select
            label="Designation"
            options={getReferenceableOptions(designations)}
            field={form.state.designation}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Start Minutes"
            type="number"
            field={form.state.start_minutes}
            onChange={(event) => {
              const value = event.target.value.replace(/^[0]/, '');
              const intValue = parseInt(value, 10);
              if (!Number.isNaN(intValue)) {
                form.state.start_minutes.onChange(intValue);
              } else if (value === '') {
                form.state.start_minutes.onChange(0);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Personal Phone Policy"
            options={personalPhonePolicyOptions}
            field={form.state.personal_phone_policy as FieldState<string>}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
}
