import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef } from 'react';

export interface FormDialogBaseProps<T> {
  onSave?: (item: T[]) => void;
  onCancel?: () => void;
  open: boolean;
}

export interface FormDialogProps extends React.PropsWithChildren {
  title: string;
  onSave: () => void;
  onCancel: () => void;
  open: boolean;
  clearItem: () => void;
}

export function FormDialog({
  open,
  title,
  onSave,
  onCancel,
  clearItem,
  children,
}: FormDialogProps) {
  const wasOpen = useRef(open);
  useEffect(() => {
    if (!wasOpen.current && open) {
      clearItem();
    }
    wasOpen.current = open;
  }, [clearItem, open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => onSave()}>Save</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
