import { isIn } from './validators';

export const personalPhonePolicyOptions = [
  {
    value: 'RESPECTFUL',
    name: 'Respectful',
  },
  {
    value: 'NONE',
    name: 'None',
  },
];

export const isValidPersonalPhonePolicy = isIn(personalPhonePolicyOptions.map((policy) => policy.value), 'phone policy');
