import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { NCInputRadioGroup, NCInputText, NCSelect } from '@daupler/nexus-components';
import {
  RRuleEnd, RRuleFrequency, RRuleKeyValue,
} from './InputRRule.d';
import InputRRuleAfter from './InputRRuleAfter';
import InputRRuleDaily from './InputRRuleDaily';
import InputRRuleHourly from './InputRRuleHourly';
import InputRRuleMontly from './InputRRuleMonthly';
import InputRRuleNever from './InputRRuleNever';
import InputRRuleOn from './InputRRuleOn';
import InputRRuleWeekly from './InputRRuleWeekly';
import InputRRuleYearly from './InputRRuleYearly';
import { getKeyValueFromRRule, keyValueToRRuleString } from '../../utils/rrule-util';
import { castToInterface } from '../../utils/type-helpers';

enum EditorType {
  STRING = 'string',
  FORM = 'form',
}

const rruleFrequencyKeys: Array<keyof RRuleKeyValue> = ['BYMONTH', 'BYMONTHDAY', 'BYSETPOS', 'BYDAY', 'INTERVAL'];
const rruleEndKeys: Array<keyof RRuleKeyValue> = ['UNTIL', 'COUNT'];

const FrequencyComponentMap = {
  [RRuleFrequency.YEARLY]: InputRRuleYearly,
  [RRuleFrequency.MONTHLY]: InputRRuleMontly,
  [RRuleFrequency.WEEKLY]: InputRRuleWeekly,
  [RRuleFrequency.DAILY]: InputRRuleDaily,
  [RRuleFrequency.HOURLY]: InputRRuleHourly,
};

const EndComponentMap = {
  [RRuleEnd.AFTER]: InputRRuleAfter,
  [RRuleEnd.NEVER]: InputRRuleNever,
  [RRuleEnd.ON]: InputRRuleOn,
};

function InputRRuleForm({ onChange, value }: {
  onChange?: (value: string) => void;
  value?: string;
}) {
  const [editorType, setEditorType] = useState<EditorType>(EditorType.FORM);
  const [
    internalValue,
    setInternalValue,
  ] = useState<string>(value ?? 'FREQ=YEARLY;BYMONTHDAY=1;BYMONTH=1');

  const [frequencyDetails, setFrequencyDetails] = useState<RRuleKeyValue>(
    castToInterface<RRuleKeyValue>(getKeyValueFromRRule(internalValue ?? ''), rruleFrequencyKeys as string[]),
  );
  const updateFrequencyDetails = (keyValue: RRuleKeyValue) => {
    setFrequencyDetails(keyValue);
  };

  const [frequency, setFrequency] = useState<RRuleFrequency>(() => {
    const rruleKeyVal = getKeyValueFromRRule(internalValue ?? '');
    return rruleKeyVal.FREQ
      ? RRuleFrequency[rruleKeyVal.FREQ as keyof typeof RRuleFrequency]
      : RRuleFrequency.YEARLY;
  });
  const updateFrequency = (event: ChangeEvent<HTMLSelectElement>) => {
    setFrequency(event.target.value as RRuleFrequency);
    setFrequencyDetails({});
  };

  const [end, setEnd] = useState<RRuleEnd>(() => {
    const rruleKeyVal = getKeyValueFromRRule(internalValue ?? '');
    if (rruleKeyVal.UNTIL) {
      return RRuleEnd.ON;
    }
    if (rruleKeyVal.COUNT) {
      return RRuleEnd.AFTER;
    }
    return RRuleEnd.NEVER;
  });
  const [endDetails, setEndDetails] = useState<RRuleKeyValue>(
    castToInterface<RRuleKeyValue>(getKeyValueFromRRule(internalValue ?? ''), rruleEndKeys as string[]),
  );
  const updateEndDetails = (keyValue: RRuleKeyValue) => {
    setEndDetails(keyValue);
  };
  const updateEnd = (event: ChangeEvent<HTMLSelectElement>) => {
    setEnd(event.target.value as RRuleEnd);
    if (event.target.value as RRuleEnd === RRuleEnd.NEVER) {
      setEndDetails({});
    }
  };

  const updateFromRruleStringEditor = () => {
    const parsed = getKeyValueFromRRule(internalValue);
    setFrequency(parsed.FREQ as RRuleFrequency ?? RRuleFrequency.YEARLY);
    setFrequencyDetails({
      ...(parsed.BYDAY ? { BYDAY: parsed.BYDAY } : {}),
      ...(parsed.BYMONTH ? { BYMONTH: parsed.BYMONTH } : {}),
      ...(parsed.BYMONTHDAY ? { BYMONTHDAY: parsed.BYMONTHDAY } : {}),
      ...(parsed.BYSETPOS ? { BYSETPOS: parsed.BYSETPOS } : {}),
      ...(parsed.INTERVAL ? { INTERVAL: parsed.INTERVAL } : {}),
    });
    setEndDetails({
      ...(parsed.UNTIL ? { UNTIL: parsed.UNTIL } : {}),
      ...(parsed.COUNT ? { COUNT: parsed.COUNT } : {}),
    });
  };

  const serializeValue = useCallback(
    () => keyValueToRRuleString({ FREQ: frequency, ...frequencyDetails, ...endDetails }),
    [endDetails, frequency, frequencyDetails],
  );

  useEffect(() => {
    const newValue = serializeValue();
    if (newValue === value) {
      return;
    }
    setInternalValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }, [frequency, frequencyDetails, endDetails, onChange, serializeValue, value]);

  const FrequencyComponent = FrequencyComponentMap[frequency];
  const EndComponent = EndComponentMap[end];

  return (
    <>
      <div className="d-flex">
        <NCInputRadioGroup
          label="Form"
          name="rrule-editor-type"
          value={editorType}
          onChange={(event) => {
            setEditorType(event.target.id as EditorType);
            updateFromRruleStringEditor();
          }}
          options={[
            {
              label: 'Form',
              id: 'form-mode',
            },
            {
              label: 'String',
              id: 'string-mode',
            },
          ]}
        />
      </div>
      {editorType === EditorType.STRING ? (
        <NCInputText
          className="dl-mt--md"
          name="rrule-string-editor"
          label="RRule String"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setInternalValue(event.target.value)}
          onBlur={updateFromRruleStringEditor}
          value={internalValue}
        />
      ) : (
        <>
          <NCSelect
            className="dl-mt--md"
            hint="How often this event occurs."
            name="rrule-repeat"
            label="Frequency"
            onChange={updateFrequency}
            options={Object.entries(RRuleFrequency).map(([key, val]) => ({
              label: val,
              value: key,
            }))}
            value={frequency}
          />

          <FrequencyComponent onChange={updateFrequencyDetails} value={internalValue ?? ''} />

          <NCSelect
            className="dl-mt--md"
            hint="Condition for the end of the set."
            name="rrule-end-type"
            label="Ends"
            onChange={updateEnd}
            options={Object.entries(RRuleEnd).map(([key, val]) => ({
              label: val,
              value: key,
            }))}
            value={end}
          />

          <EndComponent onChange={updateEndDetails} value={internalValue ?? ''} />
        </>
      )}

    </>
  );
}

export default InputRRuleForm;
