import { NCButton, NCLabel, NCWell } from '@daupler/nexus-components';
import { useState } from 'react';
import { EntityConfigModuleActionType, EntityConfigResourceActionType } from '../../types/EntityConfig';

type ResourceActionProps = {
  actionType: EntityConfigModuleActionType | EntityConfigResourceActionType;
  itemKey: string;
  type?: string;
  displayName?: string;
  details: string;
};

export function ConfigAction({
  actionType,
  itemKey,
  type,
  displayName,
  details,
}: ResourceActionProps) {
  const [showDetails, setShowDetails] = useState(false);

  const getColorForActionType = () => {
    switch (actionType) {
      case EntityConfigResourceActionType.CREATE:
      case EntityConfigModuleActionType.ENABLE:
        return NCWell.colors.SUCCESS;
      case EntityConfigResourceActionType.UPDATE:
      case EntityConfigModuleActionType.UPDATE:
        return NCWell.colors.WARNING;
      case EntityConfigResourceActionType.DELETE:
      case EntityConfigModuleActionType.DISABLE:
        return NCWell.colors.ERROR;
      default:
        return NCWell.colors.LIGHT;
    }
  };

  return (
    <>
      <div className="nc-flex">
        <p className="nc-t-sub_text_medium_mobile">{itemKey}</p>
        {type ? (
          <NCLabel
            label={type}
            size={NCLabel.sizes.SM}
            color={NCLabel.colors.GREY}
            appearance={NCLabel.appearances.OUTLINE}
            className="nc-l-ml_utilities_50_mobile"
          />
        ) : null}
      </div>
      {displayName ? (
        <p className="nc-t-body_regular_mobile">
          {displayName}
        </p>
      ) : null}
      {showDetails ? (
        <NCWell color={getColorForActionType()}>
          <pre className="nc-t-micro_text_light_mobile">
            {details}
          </pre>
        </NCWell>
      ) : null}
      <NCButton
        appearance={NCButton.appearances.LINK}
        width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
        className="nc-l-mt_utilities_50"
        onClick={() => setShowDetails(!showDetails)}
      >
        <span className="nc-t-info_text_light_mobile">
          {showDetails ? 'Hide Details' : 'Show Details'}
        </span>
      </NCButton>
    </>
  );
}
