import * as R from 'ramda';
import { lazy } from './lazy';
import { isIn } from './validators';

const mappings: Record<string, string> = {
  PST: 'US/Pacific',
  PDT: 'US/Pacific',
  MST: 'US/Mountain',
  MDT: 'US/Mountain',
  CST: 'US/Central',
  CDT: 'US/Central',
  EST: 'US/Eastern',
  EDT: 'US/Eastern',
};

export const getTimeZone = lazy(() => {
  const matches = new Date().toString().match(/\(([A-Za-z\s].*)\)/);
  if (matches != null) {
    return mappings[matches[1]];
  }
  return 'US/Pacific';
});

export const timezoneOptions = R.uniq(Object.values(mappings)).map((tz) => ({
  value: tz,
}));

export const isValidTimeZone = isIn(R.uniq(Object.values(mappings)), 'timezone');
