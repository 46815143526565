import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextField } from '../../components/TextField';
import { ServiceExtentFormState } from '../../hooks/useConfigState';

export interface ServiceExtentPageProps {
  form: ServiceExtentFormState;
}

export function ServiceExtentPage(props: ServiceExtentPageProps) {
  const { form } = props;

  return (
    <Grid container spacing={1} height="100%" minHeight="300px">
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Service Extent
        </Typography>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <TextField label="Latitude North" field={form.state.lat_north} />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <TextField label="Longitude West" field={form.state.lng_west} />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <TextField label="Longitude East" field={form.state.lng_east} />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <TextField label="Latitude South" field={form.state.lat_south} />
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
}
