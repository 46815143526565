import { NCInputCheckbox } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciSimplePromptParams, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function CciDispatchRuleFormCciSimplePrompt({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const {
    make_response_visible: makeResponseVisible,
    _prompt_ref: promptRef,
    _yes_text_ref: yesTextRef,
    _no_text_ref: noTextRef,
    _dispatched_message_ref: dispatchedMessageRef,
    _no_dispatch_message_ref: noDispatchMessageRef,
  } = (value ?? {
    _dispatched_message_ref: {},
    _no_dispatch_message_ref: {},
    _no_text_ref: {},
    _prompt_ref: {},
    _yes_text_ref: {},
  }) as CciSimplePromptParams;

  return (
    <>
      <ConfigVisualEditorParamControl
        label="Prompt"
        className="nc-l-mt_200_mobile"
        name="simple_prompt-prompt"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onChange({
          ...(value as CciSimplePromptParams),
          _message_ref: {
            key: event.target.value,
            type: EntityConfigParamType.STRING,
          },
        })}
        value={promptRef?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        label="Yes Text"
        className="nc-l-mt_200_mobile"
        name="simple_prompt-yes_text"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onChange({
          ...(value as CciSimplePromptParams),
          _yes_text_ref: {
            key: event.target.value,
            type: EntityConfigParamType.STRING,
          },
        })}
        value={yesTextRef?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        label="No Text"
        className="nc-l-mt_200_mobile"
        name="simple_prompt-no_text"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onChange({
          ...(value as CciSimplePromptParams),
          _no_text_ref: {
            type: EntityConfigParamType.STRING,
            key: event.target.value,
          },
        })}
        value={noTextRef?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        label="Dispatched Message"
        className="nc-l-mt_200_mobile"
        name="simple_prompt-dispatched_message"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onChange({
          ...(value as CciSimplePromptParams),
          _dispatched_message_ref: {
            key: event.target.value,
            type: EntityConfigParamType.STRING,
          },
        })}
        value={dispatchedMessageRef?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        label="No Dispatch Message"
        className="nc-l-mt_200_mobile"
        name="simple_prompt-no_dispach_message"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onChange({
          ...(value as CciSimplePromptParams),
          _no_dispatch_message_ref: {
            key: event.target.value,
            type: EntityConfigParamType.STRING,
          },
        })}
        value={noDispatchMessageRef?.key ?? ''}
      />
      <NCInputCheckbox
        name="simple_prompt-make_response_visible"
        label="Make Response Visible?"
        className="nc-l-mt_200_mobile"
        checked={makeResponseVisible}
        onChange={() => onChange({
          ...value as CciSimplePromptParams,
          make_response_visible: !makeResponseVisible,
        })}
      />
    </>
  );
}
