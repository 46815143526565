import { useEffect, useRef } from 'react';
import { EntityConfigAnyModule } from '../types/EntityConfig';
import { OnModuleChange } from '../components/module-forms/module-form-types';

export const useSyncModuleForm = ({
  isFormValid,
  onChange,
  onValidate,
  module,
}: {
  isFormValid: () => boolean;
  onChange: OnModuleChange;
  onValidate: (result: boolean) => void;
  module: Omit<EntityConfigAnyModule, 'module_type'>;
}) => {
  const previousModuleData = useRef(JSON.stringify(module));

  useEffect(() => {
    if (previousModuleData.current === JSON.stringify(module)) {
      return;
    }
    onChange(module);
    onValidate(isFormValid());
    previousModuleData.current = JSON.stringify(module);
  }, [isFormValid, module, onChange, onValidate]);

  return {};
};
