import { CciDispatchRuleProps } from './resource-form-types';
import { CciAlwaysDispatchParams, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function CciDispatchRuleFormAlwaysDispatch({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _message_ref: messageRef } = (value ?? {}) as CciAlwaysDispatchParams;
  return (
    <ConfigVisualEditorParamControl
      label="Message"
      className="nc-l-mt_200_mobile"
      name="always_dispatch-message"
      params={params}
      paramType={EntityConfigParamType.STRING}
      onChange={(event) => onChange({
        ...(value as CciAlwaysDispatchParams),
        _message_ref: {
          type: EntityConfigParamType.STRING,
          key: event.target.value,
        },
      })}
      value={messageRef?.key ?? ''}
    />
  );
}
