import Grid from '@mui/material/Grid';
import * as R from 'ramda';
import { useEffect } from 'react';
import { FieldOptions } from '../../hooks/useField';
import { useRefForm } from '../../hooks/useRefForm';
import {
  Designation,
  Rotation,
  RotationData,
  SimpleWeeklyRotationData,
  StubRotationData,
} from '../../types';
import { getReferenceableOptions } from '../../utils/referenceable';
import {
  isValidRotationStartDate,
  isValidRotationStartTime,
  isValidRotationType,
  rotationTypes,
} from '../../utils/rotations';

import { and, MessageValidator, required } from '../../utils/validators';
import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { MultiSelect } from '../MultiSelect';
import { Select, SelectOption } from '../Select';
import { TextField } from '../TextField';

export interface RotationsFormDialogProps
  extends FormDialogBaseProps<RotationData> {
  designations: Designation[];
}

export function RotationsFormDialog(props: RotationsFormDialogProps) {
  const {
    onSave, onCancel, open, designations,
  } = props;

  function validateIfType<T>(
    type: Rotation['type'],
    validator: MessageValidator<T>,
  ) {
    return (value: T) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (form.state.type.value === type) {
        return validator(value);
      }
      return true;
    };
  }

  const form = useRefForm({
    type: {
      value: 'Stub',
      validate: isValidRotationType,
    } as FieldOptions<Rotation['type']>,
    apply_to_designations: {
      value: [],
      validate: validateIfType('Stub', required),
    } as FieldOptions<string[]>,
    description: {
      value: '',
      validate: validateIfType('Stub', required),
    },
    switch_time: {
      value: '',
      validate: validateIfType(
        'SimpleWeeklyRotation',
        and(required, isValidRotationStartTime),
      ),
    },
    dtstart: {
      value: '',
      validate: validateIfType(
        'SimpleMonthlyRotation',
        and(required, isValidRotationStartDate),
      ),
    },
  });

  useEffect(() => {
    if (form.state.type.isValidating) {
      form.validate();
    }
  }, [form, form.state.type.value]);

  const getValues = () => {
    const rawValues = form.getValues() as unknown as RotationData;
    if (rawValues.type === 'Stub') {
      return R.omit(['switch_time'] as any, rawValues) as StubRotationData;
    }
    return R.omit(['description'] as any, rawValues) as SimpleWeeklyRotationData;
  };

  const typeOptions: SelectOption<Rotation['type']>[] = rotationTypes.map(
    (item) => ({
      key: item,
      name: item,
      value: item,
    }),
  );

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([getValues()]);
    }
  };

  const renderDescription = () => {
    if (form.state.type.value !== 'Stub') {
      return null;
    }
    return (
      <Grid item xs={12}>
        <TextField label="Description" field={form.state.description} />
      </Grid>
    );
  };

  const renderSwitchTime = () => {
    if (form.state.type.value !== 'SimpleWeeklyRotation') {
      return null;
    }
    return (
      <Grid item xs={12}>
        <TextField label="Switch Time" field={form.state.switch_time} />
      </Grid>
    );
  };

  const renderStartDate = () => {
    if (form.state.type.value !== 'SimpleMonthlyRotation') {
      return null;
    }
    return (
      <Grid item xs={12}>
        <TextField label="Start Date" field={form.state.dtstart} />
      </Grid>
    );
  };

  return (
    <FormDialog
      title="Add Rotation"
      onSave={handleSave}
      onCancel={() => onCancel?.()}
      clearItem={form.clear}
      open={open}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={12}>
          <Select label="Type" options={typeOptions} field={form.state.type} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Name" field={form.state.display_name} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Ref" field={form.state.id} />
        </Grid>
        {renderDescription()}
        {renderSwitchTime()}
        {renderStartDate()}
        <Grid item xs={12}>
          <MultiSelect
            label="Apply To Designations"
            options={getReferenceableOptions(designations)}
            field={form.state.apply_to_designations}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
}
