import { ChangeEvent, useEffect, useState } from 'react';
import { NCSelect } from '@daupler/nexus-components';
import { getKeyValueFromRRule } from '../../utils/rrule-util';
import { getCurrentYear, getDaysInMonth, getMonths } from '../../utils/datetime';

function InputRRuleYearlyMonthday({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [month, setMonth] = useState(getKeyValueFromRRule(value).BYMONTH ?? '1');
  const updateMonth = (event: ChangeEvent<HTMLSelectElement>) => {
    setMonth(event.target.value);
  };

  const [day, setDay] = useState(getKeyValueFromRRule(value).BYMONTHDAY ?? '1');
  const updateDay = (event: ChangeEvent<HTMLSelectElement>) => {
    setDay(event.target.value);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.BYMONTH !== month || parsedValue.BYMONTHDAY !== day) {
      onChange({ BYMONTH: month.toString(), BYMONTHDAY: day.toString() });
    }
  }, [day, month, onChange, value]);

  return (
    <div className="d-flex d-flex--row">
      <NCSelect
        className="dl-mt--md"
        name="rrule-yearly-month"
        label="Month"
        onChange={updateMonth}
        options={getMonths().map((monthObject) => ({
          label: monthObject.label,
          value: monthObject.index.toString(),
        }))}
        value={month.toString()}
      />
      <NCSelect
        className="dl-mt--md dl-ml--sm"
        name="rrule-yearly-day"
        label="Day"
        onChange={updateDay}
        options={new Array(getDaysInMonth(parseInt(month, 10), getCurrentYear()))
          .fill(undefined)
          .map((_, i) => ({
            label: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        value={day.toString()}
      />
    </div>
  );
}

export default InputRRuleYearlyMonthday;
