import {
  Designation,
  EscalationTree,
  FacilityPolicy,
  IncidentHandler,
  PartitionScheme,
  Rotation,
  Schedule,
  ServiceArea,
  ShutoffList,
  Utility,
  Workgroup,
} from '../types';
import { isValidModules } from '../utils/modules';
import { isValidPartitionScheme } from '../utils/partitionScheme';
import { getTimeZone, isValidTimeZone } from '../utils/timezone';
import { utilityTypes } from '../utils/utility';
import {
  and, required, maxLength, isValidPhoneNumber,
} from '../utils/validators';
import { FieldOptions } from './useField';
import { useForm } from './useForm';
import { useReferenceStore } from './useReferenceStore';

export function useConfigState(values?: ConfigStateValues) {
  const detailsForm = useForm({
    name: {
      value: values?.detailsForm.name ?? '',
      validate: required,
    },
    short_name: {
      value: values?.detailsForm.short_name ?? '',
      validate: and(required, maxLength(15)),
    },
    timezone: {
      value: values?.detailsForm.timezone ?? getTimeZone(),
      validate: and(required, isValidTimeZone),
    },
    locality: {
      value: values?.detailsForm.locality ?? '',
      validate: required,
    },
    region_code: {
      value: values?.detailsForm.region_code ?? '',
      validate: required,
    },
    country_code: {
      value: values?.detailsForm.country_code ?? 'US',
      validate: required,
      onChange: (
        value,
        current,
      ) => {
        if (value !== current) {
          detailsForm.state.region_code.onChange('');
        }
      },
    },
    forward_calls_to: {
      value: values?.detailsForm.forward_calls_to ?? '',
      validate: and(required, isValidPhoneNumber),
    },
    service_description: {
      value: values?.detailsForm.service_description ?? '',
      validate: required,
    },
    business_hours_description: {
      value: values?.detailsForm.business_hours_description ?? '',
      validate: required,
    },
    modules: {
      value: values?.detailsForm.modules ?? [
        'citizen_engagement',
        'daupler_dispatch',
      ],
      validate: isValidModules,
    } as FieldOptions<string[]>,
    partition_scheme: {
      value:
        values?.detailsForm.partition_scheme ?? PartitionScheme.SINGLE_GROUP,
      validate: and(required, isValidPartitionScheme),
    } as FieldOptions<PartitionScheme>,
  });

  const serviceExtentForm = useForm({
    lat_south: {
      value: values?.serviceExtentForm.lat_south ?? '',
      validate: required,
    },
    lat_north: {
      value: values?.serviceExtentForm.lat_north ?? '',
      validate: required,
    },
    lng_west: {
      value: values?.serviceExtentForm.lng_west ?? '',
      validate: required,
    },
    lng_east: {
      value: values?.serviceExtentForm.lng_east ?? '',
      validate: required,
    },
  });

  const workgroups = useReferenceStore<Workgroup>({
    prefix: 'workgroups',
    values: values?.workgroups,
  });
  const serviceAreas = useReferenceStore<ServiceArea>({
    prefix: 'service_areas',
    values: values?.serviceAreas,
  });
  const designations = useReferenceStore<Designation>({
    prefix: 'designations',
    values: values?.designations,
  });
  const rotations = useReferenceStore<Rotation>({
    prefix: 'rotations',
    values: values?.rotations,
  });
  const escalationTrees = useReferenceStore<EscalationTree>({
    prefix: 'escalation_trees',
    values: values?.escalationTrees,
  });
  const schedules = useReferenceStore<Schedule>({
    prefix: 'schedules',
    values: values?.schedules,
  });
  const incidentHandlers = useReferenceStore<IncidentHandler>({
    prefix: 'incident_handlers',
    values: values?.incidentHandlers,
  });
  const facilityPolicies = useReferenceStore<FacilityPolicy>({
    prefix: 'facility_policies',
    values: values?.facilityPolicies,
  });
  const shutoffLists = useReferenceStore<ShutoffList>({
    prefix: 'shutoff_lists',
    values: values?.shutoffLists,
  });
  const utilities = useReferenceStore<Utility>({
    prefix: 'utilities',
    values: values?.utilities,
  });

  utilities.references = utilities.references.sort((a, b) => {
    const aIndex = utilityTypes.indexOf(a.utility_type);
    const bIndex = utilityTypes.indexOf(b.utility_type);
    if (aIndex < bIndex) {
      return -1;
    } if (aIndex > bIndex) {
      return 1;
    }
    return 0;
  });

  return {
    detailsForm,
    serviceExtentForm,
    workgroups,
    serviceAreas,
    designations,
    rotations,
    escalationTrees,
    schedules,
    incidentHandlers,
    facilityPolicies,
    shutoffLists,
    utilities,
  };
}

export type ConfigState = ReturnType<typeof useConfigState>;
export type ConfigStateValues = {
  detailsForm: DetailsFormStateValues;
  serviceExtentForm: ServiceExtentFormStateValues;
  workgroups: Workgroup[];
  serviceAreas: ServiceArea[];
  designations: Designation[];
  rotations: Rotation[];
  escalationTrees: EscalationTree[];
  schedules: Schedule[];
  incidentHandlers: IncidentHandler[];
  facilityPolicies: FacilityPolicy[];
  shutoffLists: ShutoffList[];
  utilities: Utility[];
};

export type DetailsFormState = ConfigState['detailsForm'];
export type DetailsFormStateValues = {
  name: string;
  short_name: string;
  timezone: string;
  locality: string;
  region_code: string;
  country_code: string;
  forward_calls_to: string;
  service_description: string;
  business_hours_description: string;
  modules: string[];
  partition_scheme: PartitionScheme;
};

export type ServiceExtentFormState = ConfigState['serviceExtentForm'];
export type ServiceExtentFormStateValues = {
  lat_south: string;
  lat_north: string;
  lng_west: string;
  lng_east: string;
};
