import { PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  NCToast,
} from '@daupler/nexus-components';
import { EditorEntityParam, useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { useToast } from '../hooks/useToasts';
import { ConfigVisualEditorHeader } from './ConfigVisualEditorHeader';
import { ConfigVisualEditorObjectsMenu } from './ConfigVisualEditorObjectsMenu';
import './ConfigVisualEditorLayout.css';

export function ConfigVisualEditorLayout({
  children,
}: PropsWithChildren) {
  const [serachParams, setSearchParams] = useSearchParams();
  const [isTrayOpen, setIsTrayOpen] = useState(serachParams.get('objectsMenu') === 'true');
  useEffect(() => {
    if (serachParams.get('objectsMenu') === 'true' && !isTrayOpen) {
      setIsTrayOpen(true);
    } else if (serachParams.get('objectsMenu') === 'false' && isTrayOpen) {
      setIsTrayOpen(false);
    }
  }, [isTrayOpen, serachParams]);

  const {
    entity,
    keys,
    modules,
    params,
    saveEntityConfig,
    isSaving,
    setIsSaving,
    editorMode,
    setEditorMode,
    setSearchFilter,
    searchFilter,
  } = useEntityConfigEditor();

  const { addToast } = useToast();
  const saveConfig = () => {
    if (!entity) { return; }
    setIsSaving(true);
    saveEntityConfig();
    addToast({
      body: 'Saved config to local storage',
      id: 'yaml-foreground-save',
      subject: 'Configuration Saved',
      type: NCToast.style.SUCCESS,
    });
    setIsSaving(false);
  };

  const keysAndUsages: [string, number][] = keys.data
    .map((key) => [
      key,
      params.data.filter((param) => param.param.key === key).length,
    ]);

  const paramsAndUsages: [EditorEntityParam, number][] = params.data
    .map((param) => [
      param,
      [
        ...modules.data.filter(({ module }) => JSON.stringify(module).includes(param.param.key)),
        // ...config
        //   .resources.filter((resource) => JSON.stringify(resource).includes(param.key)),
      ].length,
    ]);

  return (
    <div className={clsx('entities_id_route', { 'entities_id_route--tray': isTrayOpen })}>
      <div className="entities_id_route__header">
        <ConfigVisualEditorHeader
          entity={entity}
          editorMode={editorMode}
          onEditorModeChange={setEditorMode}
          onSearchFilterUpdate={setSearchFilter}
          searchFilter={searchFilter}
          isObjectsMenuOpen={isTrayOpen}
          onObjectsMenuClick={() => {
            const searchParams = new URLSearchParams(serachParams.toString());
            searchParams.set('objectsMenu', (!isTrayOpen).toString());
            setSearchParams(searchParams);
          }}
          onSave={saveConfig}
          isSaving={isSaving}
        />
      </div>

      <div className="entities_id_route__workspace">
        {children}
      </div>

      {isTrayOpen ? (
        <div className="entities_id_route__tray">
          <ConfigVisualEditorObjectsMenu
            keys={keysAndUsages}
            onKeyAdd={keys.add}
            onKeyFilter={(key) => setSearchFilter(key)}
            onKeyRemove={keys.remove}
            onParamAdd={params.add}
            onParamFilter={(paramKey) => setSearchFilter(paramKey)}
            onParamRemove={params.remove}
            onParamUpdate={params.update}
            params={paramsAndUsages}
          />
        </div>
      ) : null}
    </div>
  );
}
