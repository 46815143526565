import { FormField, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigParamRef, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function ServiceAreaForm({
  entityId,
  onChange,
  onValidate,
  params,
  resourceData,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const featuresRef: FormField<EntityConfigParamRef | undefined> = {
    name: 'featuresRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: undefined,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    featuresRef,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _features_ref: formState.featuresRef.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <ConfigVisualEditorParamControl
        label="Features"
        className="nc-l-mt_200_mobile"
        name={formState.featuresRef.name}
        params={params}
        paramType={EntityConfigParamType.JSON}
        onChange={(event) => onFormChange(
          formState.featuresRef.name,
          {
            key: event.target.value,
            type: EntityConfigParamType.JSON,
          },
        )}
        value={formState.featuresRef.value?.key ?? ''}
      />
    </>
  );
}
