export interface MapExtent {
  lng_max: number;
  lat_max: number;
  lat_min: number;
  lng_min: number;
}

export const DEFAULT_LAT_LNG = {
  /* berea, ky */
  lat: 37.5687,
  lng: -84.2963,
};

export const DEFAULT_MAP_ZOOM = 10;

export type Position = number[];

export interface Polygon {
  type: 'Polygon';
  coordinates: Position[][];
}

export interface MultiPolygon {
  type: 'MultiPolygon';
  coordinates: Position[][][];
}

export type Geometry = Polygon | MultiPolygon;

export interface Feature {
  type: 'Feature';
  properties: { [name: string]: any };
  geometry: Geometry;
}

export interface FeatureCollection {
  type: 'FeatureCollection';
  features: Feature[];
}

export type GeoJson = Geometry | Feature | FeatureCollection;

type Region = {
  max: { lng: null | number; lat: null | number };
  min: { lng: null | number; lat: null | number };
};

function getMinAndMaxLatLngFromPath(path: [number, number][]): Region {
  return path.reduce((centerCoords, [lng, lat]) => {
    const updatedCoords = { ...centerCoords };
    if (!centerCoords.min.lng || lng < centerCoords.min.lng) {
      updatedCoords.min.lng = lng;
    }
    if (!centerCoords.max.lng || lng > centerCoords.max.lng) {
      updatedCoords.max.lng = lng;
    }
    if (!centerCoords.min.lat || lat < centerCoords.min.lat) {
      updatedCoords.min.lat = lat;
    }
    if (!centerCoords.max.lat || lng > centerCoords.max.lat) {
      updatedCoords.max.lat = lat;
    }
    return updatedCoords;
  }, { min: { lng: null, lat: null }, max: { lng: null, lat: null } } as Region);
}

function regionToPath(region: Region): [number, number][] {
  if (!region.min.lat || !region.min.lng) { throw new Error('Could not calculate center'); }
  if (!region.max.lat || !region.max.lng) { throw new Error('Could not calculate center'); }

  return [
    [region.max.lng, region.max.lat],
    [region.max.lng, region.min.lat],
    [region.min.lng, region.max.lat],
    [region.min.lng, region.min.lat],
  ];
}

function getCenterFromPath(path: [number, number][]): [number, number] {
  const region = getMinAndMaxLatLngFromPath(path);

  if (!region.min.lat || !region.min.lng) { throw new Error('Could not calculate center'); }
  if (!region.max.lat || !region.max.lng) { throw new Error('Could not calculate center'); }

  return [(region.min.lng + region.max.lng) / 2, (region.min.lat + region.max.lat) / 2];
}

export function getCenterFromPaths(paths: [number, number][][]): [number, number] | null {
  try {
    const pathCenters = paths.map(getCenterFromPath);
    return getCenterFromPath(pathCenters);
  } catch (err) {
    return null;
  }
}

export function getImageZoomFromPaths(
  paths: [number, number][][],
  mapDims: { height: number; width: number; },
): number | null {
  try {
    const minMaxRegions = paths.map(getMinAndMaxLatLngFromPath);
    const minMaxPaths = minMaxRegions.reduce((combinedPaths, path) => {
      const p = regionToPath(path);
      return [
        ...combinedPaths,
        ...p,
      ];
    }, [] as [number, number][]);
    const region = getMinAndMaxLatLngFromPath(minMaxPaths);
    if (!region.min.lat || !region.min.lng) { throw new Error('Could not calculate center'); }
    if (!region.max.lat || !region.max.lng) { throw new Error('Could not calculate center'); }
    const ne = { lat: region.max.lat, lng: region.min.lng };
    const sw = { lat: region.min.lat, lng: region.max.lng };
    const latFraction = (ne.lat - sw.lat) / Math.PI;
    const lngFraction = ne.lng - sw.lng;
    const latZoom = Math.floor(Math.log(mapDims.height / 256 / latFraction) / Math.LN2);
    const lngZoom = Math.floor(Math.log(mapDims.width / 256 / lngFraction) / Math.LN2);
    return Math.min(latZoom, lngZoom, DEFAULT_MAP_ZOOM);
  } catch (err) {
    return null;
  }
}

export function getLngLatTupleFromMapExtent(
  mapExtent: { lat_min: number; lat_max: number; lng_min: number; lng_max: number; },
): [number, number][] {
  return [
    [mapExtent.lng_min, mapExtent.lat_min],
    [mapExtent.lng_min, mapExtent.lat_max],
    [mapExtent.lng_max, mapExtent.lat_max],
    [mapExtent.lng_max, mapExtent.lat_min],
  ];
}

export function getCenterOrFallbackFromPaths(mapExtent: MapExtent, paths?: [number, number][][]) {
  try {
    if (paths) {
      const center = getCenterFromPaths(paths);
      if (!center) { throw new Error('Could not calcuate center'); }
      return {
        lat: center?.[1],
        lng: center?.[0],
      };
    }
    if (mapExtent) {
      const center = getCenterFromPaths([getLngLatTupleFromMapExtent(mapExtent)]);
      if (!center) { throw new Error('Could not calcuate center'); }
      return {
        lat: center?.[1],
        lng: center?.[0],
      };
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return {
    lat: DEFAULT_LAT_LNG.lat,
    lng: DEFAULT_LAT_LNG.lng,
  };
}

export function getMapExtentFromPaths(paths?: [number, number][][]): MapExtent | null {
  if (!paths?.length) { return null; }
  if (!paths.every((path) => path.length)) { return null; }
  const [lngs, lats] = paths.reduce((latsAndLngs, path) => [
    [...latsAndLngs[0], ...path.map((p) => p[0])],
    [...latsAndLngs[1], ...path.map((p) => p[1])],
  ], [[], []] as number[][]);
  return {
    lat_min: Math.min(...lats),
    lat_max: Math.max(...lats),
    lng_min: Math.min(...lngs),
    lng_max: Math.max(...lngs),
  };
}

function polygonToPath(polygon: Position[][]) {
  // Polygons are always an outer path followed by holes.  Thus we only
  // consider the first path.  Furthermore, they may be 3-dimensional in some
  // cases, so we reduce to two dimensions.
  return polygon[0].map((p) => [p[0], p[1]] as [number, number]);
}

export function getPathsFromGeoJson(data: GeoJson): [number, number][][] {
  switch (data.type) {
    case 'FeatureCollection':
      return data.features.reduce((result, feature) => (
        [...result, ...getPathsFromGeoJson(feature)]
      ), [] as [number, number][][]);
    case 'Feature':
      return getPathsFromGeoJson(data.geometry);
    case 'Polygon':
      return [polygonToPath(data.coordinates)];
    case 'MultiPolygon':
      return data.coordinates.map(polygonToPath);
    default:
      throw new Error('Unhandled GeoJSON');
  }
}

export function latLngPathToPointArray(path: { lat: number; lng: number; }[]): [number, number][] {
  return path.map(({ lat, lng }) => [lng, lat]);
}

export function pointArraytoLatLngPath(
  pointArray: [number, number][],
): { lat: number; lng: number; }[] {
  return pointArray.map(([lng, lat]) => ({ lng, lat }));
}
