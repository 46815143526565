import { useState } from 'react';

export function useField<T>(field: FieldOptions<T>) {
  const [value, setValue] = useState<T>(field.value);
  const [isValid, setIsValid] = useState(true);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [shouldValidate, setShouldValidate] = useState(false);

  const processValidation = (fieldValue: T) => {
    if (field.validate == null) {
      return true;
    }

    const result = field.validate(fieldValue);
    if (typeof result === 'string') {
      setInvalidMessage(result);
      setIsValid(false);
      return false;
    }
    setInvalidMessage('');
    setIsValid(result);
    return result;
  };

  const onChange = (newValue: T) => {
    if (field.onChange != null) {
      field.onChange(newValue, value);
    }
    setValue(newValue);
    if (shouldValidate) {
      processValidation(newValue);
    }
  };

  onChange.input = (event: Event<T>) => {
    onChange(event.target.value as unknown as T);
  };

  onChange.private = (newValue: T) => {
    setValue(newValue);
    if (shouldValidate) {
      processValidation(newValue);
    }
  };

  const validate = () => {
    setShouldValidate(true);
    return processValidation(value);
  };

  const clear = () => {
    setValue(field.value);
    setShouldValidate(false);
    setIsValid(true);
    setInvalidMessage('');
  };

  return {
    value,
    isValid,
    invalidMessage,
    onChange,
    validate,
    clear,
    isValidating: shouldValidate,
  };
}

export interface FieldOptions<T> {
  value: T;
  validate?: (value: T) => boolean | string;
  onChange?: (changed: T, current: T) => void;
  interceptChange?: (changed: T) => T;
}

export type FieldOptionsType<F extends FieldOptions<any>> = F extends FieldOptions<infer T>
  ? T
  : never;

export type FieldState<T> = ReturnType<typeof useField<T>>;

export type OnChangeEvent<T> = (event: Event<T>) => void;

export type Event<T> = {
  target: {
    value: T;
  };
};
