import Typography from '@mui/material/Typography';
import { ReferenceStore } from '../hooks/useReferenceStore';
import { Referenceable } from '../types';
import { isIn } from './validators';

export const getReferenceableOptions = <R extends Referenceable>(
  references: R[],
) => references.map((reference) => ({
    name: reference.display_name,
    value: reference.ref,
  }));

export const isValidRef = <R extends Referenceable>(
  references: R[],
  type: string,
) => {
  const refs = references.map((ref) => ref.ref);
  return (value: string) => {
    if (references.length <= 0) {
      return `Must create a ${type} first`;
    }
    return isIn(refs, type)(value);
  };
};

export const generateId = (name: string) => name.split(' ').join('_').toUpperCase();

export const getRefName = <S extends ReferenceStore<any>>(
  ref: string,
  store: S,
) => (
    store.getByRef(ref)?.display_name ?? (
      <Typography color="error">{store.nameForMissingRef(ref)}</Typography>
    )
  );
