import { useSearchParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigModule } from '../types/EntityConfig';
import { ConfigVisualEditorModuleModal } from '../components/ConfigVisualEditorModuleModal';

export function EntitiesEditModuleRoute() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    modules,
    saveEntityConfig,
    setIsSaving,
    entity,
  } = useEntityConfigEditor();

  const { moduleId } = useParams();
  const [,id] = moduleId?.split('|') ?? [];

  const handleSubmit = async (module: EntityConfigModule) => {
    if (!moduleId) { return; }
    modules.update(moduleId, module);
    setIsSaving(true);
    saveEntityConfig({ background: true });
    setIsSaving(false);
  };

  const module = modules.data.find(({ id: key }) => key === id);

  if (!entity) { return null; }
  return (
    <ConfigVisualEditorModuleModal
      entityId={entity?.id ?? ''}
      onClose={() => navigate(`/config/entities/${entity.id}?${searchParams.toString()}`)}
      module={module}
      onSubmit={handleSubmit}
      params={params.data}
      resources={resources.data}
    />
  );
}
