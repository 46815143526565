import { FormField, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import {
  EntityConfigParamRef,
  EntityConfigParamType,
  EntityConfigRef,
  EntityConfigResourceIncidentEmail,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function IncidentEmailForm({
  entityId,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      _token_ref: tokenRefValue,
      _router_ref: routerRefValue,
      _sender_pattern_ref: senderPatternRefValue,
    },
  } = resourceData as EntityConfigResourceIncidentEmail ?? { data: {} };
  const tokenRef: FormField<EntityConfigParamRef | undefined> = {
    name: 'tokenRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: tokenRefValue ?? undefined,
  };
  const routerRef: FormField<EntityConfigRef | undefined> = {
    name: 'routerRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: routerRefValue ?? undefined,
  };
  const senderPatternRef: FormField<EntityConfigParamRef | undefined> = {
    name: 'senderPatternRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: senderPatternRefValue ?? undefined,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    tokenRef,
    routerRef,
    senderPatternRef,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _token_ref: formState.tokenRef.value,
        _router_ref: formState.routerRef.value,
        _sender_pattern_ref: formState.senderPatternRef.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <ConfigVisualEditorParamControl
        label="Token"
        className="nc-l-mt_200_mobile"
        name={formState.tokenRef.name}
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onFormChange(
          formState.tokenRef.name,
          {
            key: event.target.value,
            type: EntityConfigParamType.STRING,
          },
        )}
        value={formState.tokenRef.value?.key ?? ''}
        hint="Must be a STRING param"
      />
      <ConfigVisualEditorResourceRefControl
        label="Incident Email Router"
        className="nc-l-mt_200_mobile"
        name={formState.routerRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.INCIDENT_EMAIL_ROUTERS}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.routerRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.INCIDENT_EMAIL_ROUTERS,
          },
        )}
        value={formState.routerRef.value?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        label="Evaluate Sender Pattern"
        className="nc-l-mt_200_mobile"
        name={formState.senderPatternRef.name}
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onFormChange(
          formState.senderPatternRef.name,
          {
            key: event.target.value,
            type: EntityConfigParamType.STRING,
          },
        )}
        value={formState.senderPatternRef.value?.key ?? ''}
        hint="Must be a STRING param"
      />
    </>
  );
}
