import { NCDauplerLogo, NCErrorView } from '@daupler/nexus-components';

export function ErrorNotFound() {
  return (
    <NCErrorView
      title="Not Found"
      message="Please check the URL and try again."
      logo={<NCDauplerLogo />}
    />
  );
}
