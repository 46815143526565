import {
  createContext,
  useContext,
  useState,
} from 'react';

interface ToastModel {
  id: string;
  subject: string;
  body: string;
  type?: any;
}

interface Store {
  toasts: ToastModel[];
  addToast: (toast: ToastModel) => void;
  clearToasts: () => void;
  removeToast: (id: string) => void;
}

export const ToastContext = createContext<Store>({} as Store);

export const useProvideToast = () => {
  const [toasts, setToasts] = useState<ToastModel[]>([]);

  const removeToast = (toastId: string) => {
    setToasts((state) => state.filter((t) => t.id !== toastId));
  };

  const addToast = (toast: ToastModel) => {
    setToasts((state) => [...state, { ...toast }]);
    setTimeout(() => {
      removeToast(toast.id);
    }, 5000);
  };

  const clearToasts = () => { setToasts([]); };

  return {
    toasts,
    addToast,
    clearToasts,
    removeToast,
  };
};

export const useToast = () => useContext(ToastContext);
