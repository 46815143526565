import {
  createContext,
  useContext,
  useState,
} from 'react';
import { DauplerApi } from '../lib/daupler-api';

export interface AuthenticationStore {
  authToken: string | null;
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthenticationContext = createContext<AuthenticationStore>({} as AuthenticationStore);

const STORAGE_KEY = 'DPLR-ICG-AUTH';

export const useProvideAuthentication = ({ api }: { api: DauplerApi }) => {
  const [state, setState] = useState<{ token: string | null }>({
    token: localStorage.getItem(STORAGE_KEY) ?? null,
  });

  const setAuthToken = (token: string | null) => {
    setState((currentState) => ({ ...currentState, token }));
    if (!token) { return; }
    localStorage.setItem(STORAGE_KEY, token);
  };

  const login = async (username: string, password: string) => {
    const token = await api.login({ username, password });
    setAuthToken(token);
  };

  const logout = async () => { setAuthToken(null); };

  return {
    authToken: state.token,
    isAuthenticated: state.token !== null,
    login,
    logout,
  };
};

export const useAuthentication = () => useContext(AuthenticationContext);
