import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DetailsForm } from '../../components/DetailsForm';
import { DetailsFormState } from '../../hooks/useConfigState';

export interface DetailsPageProps {
  detailsForm: DetailsFormState;
}

export function DetailsPage(props: DetailsPageProps) {
  const { detailsForm } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography align="left" variant="h3">
          Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DetailsForm form={detailsForm} />
      </Grid>
    </Grid>
  );
}
