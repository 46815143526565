import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './DataTable.css';

export interface DataTableProps<T> {
  schema: TableSchema<T>;
  data: T[];
  hint?: string;
  onDelete?: (item: T, index: number) => void;
  onAdd?: () => void;
  inPaper?: boolean;
}

export function DataTable<T>({
  schema,
  data,
  onDelete,
  onAdd,
  hint,
  inPaper = true,
}: DataTableProps<T>) {
  const renderColumnCell = (key: string) => {
    const column = schema[key as keyof typeof schema]!;

    if (column.renderHeader != null) {
      return column.renderHeader(column.header);
    }
    return (
      <TableCell width={column.width} key={column.header}>
        {column.header}
      </TableCell>
    );
  };

  const renderColumnRow = (rowSchema: TableSchema<T>) => (
    <TableRow>
      {Object.keys(rowSchema).map(renderColumnCell)}
      <TableCell width="56px" key="actions">
        <Button onClick={() => (onAdd != null ? onAdd() : null)}>
          <AddIcon />
        </Button>
      </TableCell>
    </TableRow>
  );

  function renderValueCell<K extends keyof T>(
    key: K,
    cellSchema: TableSchemaColumn<T[K]>,
    item: T,
    index: number,
  ) {
    const getValue = () => {
      const value = item[key];
      if (cellSchema.renderValue != null) {
        return cellSchema.renderValue(value);
      } if (typeof value === 'object') {
        return value as unknown as React.ReactNode;
      } if (value == null) {
        return '';
      }
      return `${value}`;
    };

    return (
      <TableCell key={`${key.toString()}-${index}`}>{getValue()}</TableCell>
    );
  }

  function renderValueRow(item: T, index: number) {
    const cells = Object.keys(schema).map((key: string) => renderValueCell(
      key as keyof T,
      schema[key as keyof T]!,
      item,
      index,
    ));

    return (
      <TableRow>
        {cells}
        <TableCell key={`actions-${index}`}>
          <Button
            className="actionButton"
            onClick={() => (onDelete != null ? onDelete(item, index) : null)}
          >
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  const children = (
    <>
      <Table>
        <TableHead>{renderColumnRow(schema)}</TableHead>
        <TableBody>{data.map(renderValueRow)}</TableBody>
      </Table>
      {hint != null && data.length <= 0 ? (
        <Typography variant="body2" margin="16px" style={{ opacity: 0.4 }}>
          {hint ?? ''}
        </Typography>
      ) : null}
    </>
  );

  if (inPaper) {
    return (
      <TableContainer component={Paper} elevation={2}>
        {children}
      </TableContainer>
    );
  }
  return (
    <TableContainer component={Paper} elevation={2} variant="outlined">
      {children}
    </TableContainer>
  );
}

export type TableSchema<T> = {
  [K in keyof T]?: TableSchemaColumn<T[K]>;
};

export interface TableSchemaColumn<T> {
  header: string;
  width?: string | number;
  renderHeader?: (name: string) => JSX.Element;
  renderValue?: (value: T) => React.ReactNode;
}
