import { FormField } from '@daupler/nexus-components';
import { EntityConfigParam, EntityConfigRef, EntityConfigResource } from '../types/EntityConfig';
import { EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';

type GetBaseResourceFormFieldsOptions = {
  resourceData: Omit<EntityConfigResource, 'type'> | null;
};

export function getBaseResourceFormFields({
  resourceData,
}: GetBaseResourceFormFieldsOptions) {
  const { _workgroup_ref: workgroupRefValue } = resourceData ?? {};

  const key: FormField<string> = {
    invalidMessage: '',
    name: 'key',
    validate: (value) => !!value,
    validMessage: '',
    value: resourceData?.key ?? '',
  };
  const displayName: FormField<string> = {
    invalidMessage: '',
    name: 'displayName',
    validate: (value) => !!value,
    validMessage: '',
    value: resourceData?.display_name ?? '',
  };
  const workgroupRef: FormField<EntityConfigRef | undefined> = {
    invalidMessage: '',
    name: 'workgroupRef',
    validate: () => true,
    validMessage: '',
    value: workgroupRefValue ?? undefined,
  };

  return {
    key,
    displayName,
    workgroupRef,
  };
}

export function resourceToRef(resource?: EntityConfigResource) {
  if (!resource) { return undefined; }
  return {
    key: resource.key,
    type: resource.type,
  };
}

export function getResourceRefFromId(id: string, resources: EditorEntityResource[]) {
  return resourceToRef(resources.find((resource) => resource.id === id)?.resource);
}

export function paramToRef(param?: EntityConfigParam) {
  if (!param) { return undefined; }
  return {
    key: param.key,
    type: param.type,
  };
}

export function getParamRefFromId(id: string, params: EditorEntityParam[]) {
  return paramToRef(params.find((param) => param.id === id)?.param);
}
