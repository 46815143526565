import Grid from '@mui/material/Grid';
import { FieldOptions } from '../../hooks/useField';
import { useRefForm } from '../../hooks/useRefForm';
import { Schedule, ScheduleData } from '../../types';

import { required } from '../../utils/validators';
import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { TextField } from '../TextField';

export interface ScheduleFormDialogProps
  extends FormDialogBaseProps<ScheduleData> {}
export function ScheduleFormDialog(props: ScheduleFormDialogProps) {
  const { open, onSave, onCancel } = props;

  const form = useRefForm({
    type: {
      value: 'Stub',
    } as FieldOptions<Schedule['type']>,
    description: {
      value: '',
      validate: required,
    },
  });

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([form.getValues()]);
    }
  };

  return (
    <FormDialog
      title="Add Schedule"
      open={open}
      onSave={handleSave}
      onCancel={() => onCancel?.()}
      clearItem={form.clear}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={12}>
          <TextField label="Name" field={form.state.display_name} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Ref" field={form.state.id} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Description" field={form.state.description} />
        </Grid>
      </Grid>
    </FormDialog>
  );
}
