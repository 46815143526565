import { CciDispatchRuleProps } from './resource-form-types';
import { CciRedirectParams, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function CciDispatchRuleFormNeverRedirect({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _redirect_to_ref_list: refList } = (value ?? {}) as CciRedirectParams;
  return (
    <ConfigVisualEditorParamControl
      label="Redirects"
      className="nc-l-mt_200_mobile"
      multiple
      name="redirect-message"
      params={params}
      paramType={EntityConfigParamType.STRING}
      onChange={(event) => {
        const values = Array.from(event.target.options)
          .filter((option) => option.selected)
          .map((option) => option.value);
        onChange({
          ...(value as CciRedirectParams),
          _redirect_to_ref_list: values.map((val) => ({
            key: val,
            type: EntityConfigParamType.STRING,
          })),
        });
      }}
      value={(refList ?? []).map((ref) => ref.key)}
    />
  );
}
