import * as R from 'ramda';
import yaml from 'js-yaml';
import {
  Designation,
  EscalationTree,
  FacilityPolicy,
  IncidentHandler,
  Referenceable,
  Rotation,
  Schedule,
  ServiceArea,
  ShutoffList,
  Utility,
  Workgroup,
} from '../types';
import {
  ConfigState,
  ConfigStateValues,
  DetailsFormStateValues,
  ServiceExtentFormStateValues,
} from '../hooks/useConfigState';
import { logger } from './logger';

const prepareReferencable = (reference: Referenceable) => ({
  ...R.omit(['id', 'ref'], reference),
  ref: reference.id,
});

const prepareUtilities = (utility: any) => {
  const prepared = {
    ...R.omit(['display_name', 'service_area', 'shutoff_list', 'ref'], utility),
  };

  if (utility.service_area != null) {
    prepared.service_area = utility.service_area;
  }

  if (utility.shutoff_list != null) {
    prepared.shutoff_list = utility.shutoff_list;
  }

  return prepared;
};

const loadReferencable = (
  key: string,
) => <R extends Referenceable>(reference: PreparedReferencable<R>) => ({
  ...R.omit(['ref'] as any, reference),
  id: reference.ref,
  ref: `${key}:${reference.ref}`,
} as R);

const normalizeIncidentHandlerType = (incidentHandler: IncidentHandler): IncidentHandler => ({
  ...incidentHandler,
  type: incidentHandler.type.startsWith('SimpleAfterHoursEscalation')
    ? 'SimpleAfterHoursEscalation'
    : incidentHandler.type,
} as IncidentHandler);

const loadUtility = (
  utility: PreparedUtility<PreparedReferencable<Utility>>,
): PreparedReferencable<Utility> => ({
  ...utility,
  display_name: utility.utility_type,
  ref: utility.utility_type,
});

export const generateConfig = (options: ConfigState) => {
  const {
    detailsForm,
    serviceExtentForm,
    workgroups,
    serviceAreas,
    designations,
    rotations,
    escalationTrees,
    schedules,
    incidentHandlers,
    facilityPolicies,
    shutoffLists,
    utilities,
  } = options;

  const config = {
    ...detailsForm.getValues(),
    business_hours_description:
      detailsForm.state.business_hours_description.value.split('\n'),
    modules: detailsForm.state.modules.value.reduce(
      (acc, value) => ({
        ...acc,
        [value]: true,
      }),
      {},
    ),
    service_extent: serviceExtentForm.getValues(),
    workgroups: workgroups.references.map(prepareReferencable),
    service_areas: serviceAreas.references.map(prepareReferencable),
    designations: designations.references.map(prepareReferencable),
    rotations: rotations.references.map(prepareReferencable),
    escalation_trees: escalationTrees.references.map(prepareReferencable),
    schedules: schedules.references.map(prepareReferencable),
    incident_handlers: incidentHandlers.references.map(prepareReferencable),
    facility_policies: facilityPolicies.references.map(prepareReferencable),
    shutoff_lists: shutoffLists.references.map(prepareReferencable),
    utilities: utilities.references
      .map(prepareReferencable)
      .map(prepareUtilities),
  };

  return yaml.dump(config, {
    indent: 2,
  });
};

export const readConfig = (contents: string): ConfigStateValues | null => {
  const result = {
    workgroups: [],
    service_areas: [],
    designations: [],
    rotations: [],
    escalation_trees: [],
    schedules: [],
    incident_handlers: [],
    facility_policies: [],
    shutoff_lists: [],
    utilities: [],
    ...(yaml.load(contents) as ConfigObject),
  };

  const detailsKeys = [
    'country_code',
    'forward_calls_to',
    'locality',
    'name',
    'partition_scheme',
    'region_code',
    'service_description',
    'short_name',
    'timezone',
  ];
  const referenceableKeys = [
    'workgroups',
    'service_areas',
    'designations',
    'rotations',
    'escalation_trees',
    'schedules',
    'incident_handlers',
    'facility_policies',
    'shutoff_lists',
    'utilities',
  ];
  const otherKeys = [
    'service_extent',
    'business_hours_description',
    'modules',
    'version',
    'locate_emails',
    'alarm_emails',
  ];

  const keysAreValid = Object.keys(result).reduce((acc, key) => {
    if (acc === false) {
      return acc;
    }
    if (
      detailsKeys.includes(key)
        || referenceableKeys.includes(key)
        || otherKeys.includes(key)
    ) {
      return true;
    }
    logger.log(`Bad key found ${key}`);
    return false;
  }, true);

  if (!keysAreValid) {
    return null;
  }

  return {
    detailsForm: {
      ...R.pick(detailsKeys as any, result),
      business_hours_description: result.business_hours_description.join('\n'),
      modules: Object.entries(result.modules)
        .filter(([, value]) => value !== false)
        .map(([key]) => key),
    },
    serviceExtentForm: result.service_extent,
    workgroups: result.workgroups.map(loadReferencable('workgroups')),
    serviceAreas: result.service_areas.map(loadReferencable('service_areas')),
    designations: result.designations.map(loadReferencable('designations')),
    rotations: result.rotations.map(loadReferencable('rotations')),
    escalationTrees: result.escalation_trees.map(
      loadReferencable('escalation_trees'),
    ),
    schedules: result.schedules.map(loadReferencable('schedules')),
    incidentHandlers: result.incident_handlers.map(
      loadReferencable('incident_handlers'),
    ).map(normalizeIncidentHandlerType),
    facilityPolicies: result.facility_policies.map(
      loadReferencable('facility_policies'),
    ),
    shutoffLists: result.shutoff_lists.map(loadReferencable('shutoff_lists')),
    utilities: result.utilities
      .map(loadUtility)
      .map(loadReferencable('utilities')),
  };
};

export type ConfigObject = Omit<
DetailsFormStateValues,
'business_hours_description' | 'modules'
> & {
  service_extent: ServiceExtentFormStateValues;
  business_hours_description: string[];
  modules: {
    [key: string]: boolean;
  };
} & ConfigObjectReferences;

export type ConfigObjectReferences = {
  workgroups?: PreparedReferencable<Workgroup>[];
  service_areas?: PreparedReferencable<ServiceArea>[];
  designations?: PreparedReferencable<Designation>[];
  rotations?: PreparedReferencable<Rotation>[];
  escalation_trees?: PreparedReferencable<EscalationTree>[];
  schedules?: PreparedReferencable<Schedule>[];
  incident_handlers?: PreparedReferencable<IncidentHandler>[];
  facility_policies?: PreparedReferencable<FacilityPolicy>[];
  shutoff_lists?: PreparedReferencable<ShutoffList>[];
  utilities?: PreparedUtility<PreparedReferencable<Utility>>[];
};

export type PreparedReferencable<R extends Referenceable> = Omit<R, 'id'>;
export type PreparedUtility<U> = Omit<U, 'display_name'>;
