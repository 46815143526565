import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { parse as parseYaml } from 'yaml';
import { EntityConfigEditorContext, useProvideEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { logger } from '../utils/logger';
import { useAuthentication } from '../hooks/useAuthentication';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { EntityConfig } from '../types/EntityConfig';
import { EntityDetail } from '../types/Entity';

export function ProvideEntityConfigEditor({
  children,
}: React.PropsWithChildren) {
  const { authToken } = useAuthentication();
  const { id } = useParams();
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));

  const [entityConfig, setEntityConfig] = useState<EntityConfig | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getEntityConfig = async () => {
      if (!authToken) { return; }
      if (!id) { return; }
      try {
        setIsLoading(true);
        const configResponse = await dauplerApi.current.getEntityConfig(authToken, id);
        if (configResponse) {
          setEntityConfig(parseYaml(configResponse.data.attributes.configuration_yaml));
        } else {
          setEntityConfig({
            modules: [],
            params: [],
            resources: [],
          });
        }
      } catch (err) {
        logger.error((err as Error).message, err);
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoading) { return; }
    if (entityConfig) { return; }
    getEntityConfig();
  }, [authToken, entityConfig, id, isLoading]);

  const [entity, setEntity] = useState<EntityDetail | null>(null);
  useEffect(() => {
    const getEntities = async () => {
      if (!authToken) { return; }
      if (!id) { return; }
      setIsLoading(true);
      try {
        const [
          entityResponse,
        ] = await Promise.all([
          dauplerApi.current.getEntity(authToken, id),
          dauplerApi.current.getTimezones(authToken),
          dauplerApi.current.getEntities(authToken),
        ]);
        setEntity(entityResponse);
      } catch (err) {
        logger.error((err as Error).message, err);
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoading) { return; }
    if (entity) { return; }
    getEntities();
  }, [authToken, entity, id, isLoading]);

  const store = useProvideEntityConfigEditor({
    entityConfig,
    entityId: id ?? '',
    entity,
  });
  const hasSetConfig = useRef(false);
  useEffect(() => {
    if (!entityConfig) { return; }
    if (hasSetConfig.current) { return; }
    const data = store.loadDataForEntity(id ?? '');
    store.setEntityConfig(data[data.length - 1]?.config ?? entityConfig);
    hasSetConfig.current = true;
  }, [entityConfig, id, store]);

  return (
    <EntityConfigEditorContext.Provider value={store}>
      {children}
    </EntityConfigEditorContext.Provider>
  );
}
