import { NCDauplerLogo, NCErrorView } from '@daupler/nexus-components';

export function ErrorUnknown({ errorMessage }: { errorMessage: string }) {
  return (
    <NCErrorView
      title="Unknown Error"
      message="Sorry, something went wrong."
      error={errorMessage}
      logo={<NCDauplerLogo />}
    />
  );
}
