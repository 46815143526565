import './ConfigVisualEditor.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EditorMode } from '../types/ConfigEditor';
import { EntityConfig, EntityConfigResourceType } from '../types/EntityConfig';
import { EditorEntityModule, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorCode } from './ConfigVisualEditorCode';
import { ConfigVisualEditorSection } from './ConfigVisualEditorSection';
import { ConfigVisualEditorModule } from './ConfigVisualEditorModule';
import { ConfigVisualEditorResource } from './ConfigVisualEditorResource';

type ConfigVisualEditorProps = {
  configurationObject: EntityConfig | null;
  editorMode: EditorMode;
  modules: EditorEntityModule[];
  onYamlChange: (config: string) => void;
  resources: EditorEntityResource[];
  searchFilter: string;
};

export function ConfigVisualEditor({
  configurationObject,
  editorMode,
  resources,
  modules,
  searchFilter,
  onYamlChange,
}: ConfigVisualEditorProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // const findRefs = (
  //   obj: any,
  //   filters: string[],
  // ) => Object.entries(obj)
  //   .some(([, value]) => filters.every((filter) => JSON.stringify(value).includes(filter)));

  // const buildResourceTree = (
  //   resourcesToFilter: EntityConfigResource[],
  // ): any => resourcesToFilter.reduce((result, resource) => ({
  //   ...result,
  //   [`${resource.type}-${resource.key}`]: {
  //     ...buildResourceTree(resourcesToFilter
  //       .filter((r) => findRefs(r, [resource.key, resource.type]))),
  //   },
  // }), {});
  // const resourceTree = buildResourceTree(resources.map(({ resource }) => resource));
  // console.log(resourceTree);

  const resourcesByType = Object.values(EntityConfigResourceType)
    .sort((l, r) => {
      if (l > r) { return 1; }
      if (r > l) { return -1; }
      return 0;
    })
    .reduce((result, type) => ({
      ...result,
      [type]: resources
        .filter(({ resource }) => resource.type === type)
        .filter((resource) => (searchFilter
          ? JSON.stringify(resource).toLowerCase().includes(searchFilter.toLowerCase())
          : true)),
    }), {} as Record<EntityConfigResourceType, EditorEntityResource[]>);

  if (editorMode === EditorMode.YAML) {
    return (
      <ConfigVisualEditorCode
        onYamlChange={onYamlChange}
        configurationObject={configurationObject}
      />
    );
  }

  return (
    <div className="config_visual_editor__workspace">
      <ConfigVisualEditorSection<EditorEntityModule>
        title="Modules"
        items={modules
          .filter((module) => (searchFilter
            ? JSON.stringify(module).toLowerCase().includes(searchFilter.toLowerCase())
            : true))
          .map(({ module, id }) => ({
            module,
            id: `${module.module_type}|${id}`,
          }))}
        renderItem={({ module, id }) => (
          <ConfigVisualEditorModule
            moduleType={module.module_type}
            data={module.data}
            onEdit={() => navigate(`modules/${id}?${searchParams.toString()}`)}
          />
        )}
      />

      {Object.entries(resourcesByType)
        .map(([type, resourcesForType]) => (resourcesForType.length ? (
          <div key={type} className="nc-l-mt_200_mobile">
            <ConfigVisualEditorSection<EditorEntityResource>
              items={resourcesForType
                .map(({ resource, id }) => ({
                  resource,
                  id: `${resource.type}|${id}`,
                }))}
              renderItem={({ resource, id }) => (
                <ConfigVisualEditorResource
                  type={resource.type}
                  resourceKey={resource.key}
                  displayName={resource.display_name}
                  onEdit={() => navigate(`resources/${id}?${searchParams.toString()}`)}
                />
              )}
              title={type as EntityConfigResourceType}
            />
          </div>
        ) : null))}
    </div>
  );
}
