import {
  FieldOptions,
  FieldOptionsType,
  FieldState,
  useField,
} from './useField';

export const useForm = <R extends FormRecord>(form: Form<R>): FormResult<Form<R>> => {
  const state = Object.keys(form).reduce((result, key) => ({
    ...result,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [key]: useField(form[key]),
  }), {} as FormState<Form<R>>);

  const validate = () => Object.keys(state).reduce((acc, key) => {
    const result = state[key].validate();
    return result && acc;
  }, true);

  const onChange = <K extends keyof Form<R>>(
    field: K,
    value: FieldOptionsType<Form<R>[K]>,
  ) => {
    state[field].onChange(value);
  };

  const clear = () => {
    Object.values(state).forEach((field) => field.clear());
  };

  const getValues = (): FormValues<Form<R>> => Object.keys(state).reduce((acc, key) => ({
    ...acc,
    [key]: state[key].value,
  }), {} as FormValues<Form<R>>);

  return {
    state,
    validate,
    onChange,
    getValues,
    clear,
  };
};

export type FormRecord = Record<string, FieldOptions<any>>;
export type Form<F extends FormRecord> = {
  [K in keyof F]: F[K]
};
export type FormState<F extends Form<any>> = {
  [K in keyof F]: FieldState<FieldOptionsType<F[K]>>
};
export type FormValues<F extends Form<any>> = {
  [K in keyof F]: FieldOptionsType<F[K]>
};
export interface FormResult<F extends Form<any>> {
  state: FormState<F>;
  validate: () => boolean;
  onChange: <K extends keyof F>(
    field: K,
    value: FieldOptionsType<F[K]>
  ) => void;
  getValues: () => FormValues<F>
  clear: () => void
}
