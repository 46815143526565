import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { DataTable, TableSchema } from '../../components/DataTable';
import { ShutoffListFormDialog } from '../../components/forms/ShutoffListFormDialog';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { IncidentHandler, ShutoffList, Workgroup } from '../../types';
import { getRefName } from '../../utils/referenceable';

export interface ShutoffListPageProps {
  workgroups: ReferenceStore<Workgroup>;
  incidentHandlers: ReferenceStore<IncidentHandler>;
  shutoffLists: ReferenceStore<ShutoffList>;
}

export function ShutoffListPage(props: ShutoffListPageProps) {
  const { workgroups, incidentHandlers, shutoffLists } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<ShutoffList> = {
    id: {
      header: 'Ref',
    },
    workgroup: {
      header: 'Workgroup',
    },
    display_name: {
      header: 'Name',
    },
    incident_handler: {
      header: 'Incident Handler',
    },
  };

  const mapRefs = (item: ShutoffList): ShutoffList => ({
    ...item,
    workgroup: getRefName(item.workgroup, workgroups),
    incident_handler: getRefName(item.incident_handler, incidentHandlers),
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Shutoff Lists
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ShutoffListFormDialog
          workgroups={workgroups.references}
          incidentHandlers={incidentHandlers.references}
          open={isOpen}
          onCancel={() => setOpen(false)}
          onSave={(items) => {
            shutoffLists.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={shutoffLists.references.map(mapRefs)}
          onAdd={() => setOpen(true)}
          onDelete={(item) => shutoffLists.deleteReferences(item)}
          hint="No Shutoff Lists Created"
        />
      </Grid>
    </Grid>
  );
}
