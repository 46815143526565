import { EntityConfigResourceType } from '../types/EntityConfig';
import { CciDispatchRuleForm } from './resource-forms/CciDispatchRuleForm';
import { CciScriptsForm } from './resource-forms/CciScriptsForm';
import { DispatchActionForm } from './resource-forms/DispatchActionForm';
import { DispatchConditionForm } from './resource-forms/DispatchConditionForm';
import { DispatchPlaybookForm } from './resource-forms/DispatchPlaybookForm';
import { DispatchPolicyForm } from './resource-forms/DispatchPolicyForm';
import { EscalationTreeForm } from './resource-forms/EscalationTreeForm';
import { FacilityPolicyForm } from './resource-forms/FacilityPolicyForm';
import { IncidentEmailForm } from './resource-forms/IncidentEmailForm';
import { IncidentEmailProcessorsForm } from './resource-forms/IncidentEmailProcessorsForm';
import { IncidentEmailRouterForm } from './resource-forms/IncidentEmailRouterForm';
import { IncidentRoleForm } from './resource-forms/IncidentRoleForm';
import { IncidentTransferForm } from './resource-forms/IncidentTransferForm';
import { IncidentVersionForm } from './resource-forms/IncidentVersionForm';
import { ResourceFormProps } from './resource-forms/resource-form-types';
import { ScheduleForm } from './resource-forms/ScheduleForm';
import { ServiceAreaForm } from './resource-forms/ServiceAreaForm';
import { ServiceCategoryForm } from './resource-forms/ServiceCategoryForm';
import { ServiceListForm } from './resource-forms/ServiceListForm';
import { ShiftForm } from './resource-forms/ShiftForm';
import { WorkgroupForm } from './resource-forms/WorkgroupForm';
import { WorkgroupRoleForm } from './resource-forms/WorkgroupRoleForm';

type ConfigVisualEditorResourceFormsProps = {
  type: EntityConfigResourceType;
} & ResourceFormProps;

export function ConfigVisualEditorResourceForms({
  entityId,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  type,
  workgroups,
}: ConfigVisualEditorResourceFormsProps) {
  switch (type) {
    case EntityConfigResourceType.CCI_DISPATCH_RULES:
      return (
        <CciDispatchRuleForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.CCI_SCRIPTS:
      return (
        <CciScriptsForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.DISPATCH_ACTIONS:
      return (
        <DispatchActionForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.DISPATCH_CONDITIONS:
      return (
        <DispatchConditionForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.DISPATCH_PLAYBOOKS:
      return (
        <DispatchPlaybookForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.DISPATCH_POLICIES:
      return (
        <DispatchPolicyForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.ESCALATION_TREES:
      return (
        <EscalationTreeForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.FACILITY_POLICIES:
      return (
        <FacilityPolicyForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.INCIDENT_EMAILS:
      return (
        <IncidentEmailForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS:
      return (
        <IncidentEmailProcessorsForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.INCIDENT_EMAIL_ROUTERS:
      return (
        <IncidentEmailRouterForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.INCIDENT_ROLES:
      return (
        <IncidentRoleForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.INCIDENT_TRANSFERS:
      return (
        <IncidentTransferForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.INCIDENT_VERSIONS:
      return (
        <IncidentVersionForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.SCHEDULES:
      return (
        <ScheduleForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.SERVICE_AREAS:
      return (
        <ServiceAreaForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.SERVICE_CATEGORIES:
      return (
        <ServiceCategoryForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.SERVICE_LISTS:
      return (
        <ServiceListForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.SHIFTS:
      return (
        <ShiftForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.WORKGROUPS:
      return (
        <WorkgroupForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    case EntityConfigResourceType.WORKGROUP_ROLES:
      return (
        <WorkgroupRoleForm
          entityId={entityId}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resourceData={resourceData}
          resources={resources}
          resourceType={type}
          workgroups={workgroups}
        />
      );
    default:
      return <p>Unknown resource type provided</p>;
  }
}
