import { Rotation } from '../types';
import { isIn } from './validators';

const days = [
  'mon',
  'monday',
  'tue',
  'tuesday',
  'wed',
  'wednesday',
  'thu',
  'thursday',
  'fri',
  'friday',
];

export const rotationTypes: Rotation['type'][] = [
  'Stub',
  'SimpleWeeklyRotation',
  'SimpleMonthlyRotation',
];

export const isValidRotationType = isIn(rotationTypes, 'rotation type');

export const isValidRotationStartDate = (value: string) => {
  const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;
  if (regex.test(value)) {
    return true;
  }
  return 'Expected form is "YYYY-MM-DDTHH:MM';
};

export const isValidRotationStartTime = (value: string) => {
  const regex = /(\w+)\sat\s\d{1,2}:\d{2}\s(pm|am)/;
  const matches = value.toLocaleLowerCase().match(regex);
  if (matches != null && matches.length > 0 && days.includes(matches[1])) {
    return true;
  }
  return 'Expected form is "[Day] at [Time]" Ex Wed at 2:00 PM';
};
