import clsx from 'clsx';
import { NCButton } from '@daupler/nexus-components';
import { useEffect, useState } from 'react';
import './ConfigVisualEditorSection.css';
import { useSearchParams } from 'react-router-dom';

type ConfigVisualEditorSectionProps<T extends { id: string }> = {
  items: T[];
  renderItem: (item: T) => React.JSX.Element;
  title: string;
};

export function ConfigVisualEditorSection<T extends { id: string; }>({
  items,
  renderItem,
  title,
}: ConfigVisualEditorSectionProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const collapsedParam = searchParams.get('collapsed')?.split(',') ?? [];
  const [isCollapsed, setIsCollapsed] = useState(collapsedParam.includes(title));
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const val = searchParams.get('collapsed')?.split(',') ?? [];

    if (!isCollapsed && !val.includes(title)) { return; }
    if (isCollapsed && val.includes(title)) { return; }

    if (isCollapsed && !val.includes(title)) {
      params.set('collapsed', [...val, title].join(','));
    }
    if (!isCollapsed && val.includes(title)) {
      params.set('collapsed', val.filter((v) => v !== title).join(','));
    }

    setSearchParams(params);
  }, [isCollapsed, searchParams, setSearchParams, title]);

  return (
    <div className="config_visual_editor_section">
      <div className="config_visual_editor_section__header">
        <h2 className="nc-t-h5_medium_mobile">{title}</h2>
        <NCButton
          color={NCButton.colors.LIGHT}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          {isCollapsed ? (
            <i className="fa-solid fa-chevron-right" />
          ) : (
            <i className="fa-solid fa-chevron-down" />
          )}
        </NCButton>
      </div>

      <div
        className={clsx('config_visual_editor_section__drawer', {
          'config_visual_editor_section__drawer--closed': isCollapsed,
        })}
      >

        {items.length === 0 ? <p className="nc-t-body_light_mobile nc-t-grey_600 nc-l-mt_utilities_50_mobile">No items.</p> : null}

        {items
          .map((item) => (
            <div key={item.id} className="config_visual_editor_section__node">
              {renderItem(item)}
            </div>
          ))}
      </div>
    </div>
  );
}
