import { NCSelect } from '@daupler/nexus-components';
import { ComponentProps } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  EntityConfigResourceType,
} from '../types/EntityConfig';
import { EditorEntityResource } from '../hooks/useEntityConfigEditor';
import './ConfigVisualEditorResourceRefControl.css';

type ConfigVisualEditorResourceRefControlProps = Omit<ComponentProps<typeof NCSelect>, 'options'> & {
  entityId: string;
  resources: EditorEntityResource[];
  resourceType: EntityConfigResourceType;
};

export function ConfigVisualEditorResourceRefControl(
  props: ConfigVisualEditorResourceRefControlProps,
) {
  const {
    entityId,
    resources,
    resourceType,
    value,
  } = props;

  const [searchParams] = useSearchParams();

  return (
    <div>
      <NCSelect
        // 2024-12-11 pdb: passing through props from a wrapper is OK imo
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource: { type } }) => resourceType === type)
            .map(({ id, resource }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
      />
      {value ? (
        <div className="nc-l-mt_utilities_50_mobile">
          <Link className="config_visual_editor_resource_ref_control__ref_link" to={`/config/entities/${entityId}/resources/${resourceType}|${value}?${searchParams.toString()}`}>
            View Resource
          </Link>
        </div>
      ) : null}
    </div>
  );
}
