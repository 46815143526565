import Grid from '@mui/material/Grid';
import { useRefForm } from '../../hooks/useRefForm';
import { FacilityPolicyData, IncidentHandler, Workgroup } from '../../types';
import { getReferenceableOptions, isValidRef } from '../../utils/referenceable';

import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { Select } from '../Select';
import { TextField } from '../TextField';

export interface FacilityPolicyFormDialogProps
  extends FormDialogBaseProps<FacilityPolicyData> {
  workgroups: Workgroup[];
  incidentHandlers: IncidentHandler[];
}

export function FacilityPolicyFormDialog(props: FacilityPolicyFormDialogProps) {
  const {
    open, onCancel, onSave, workgroups, incidentHandlers,
  } = props;

  const form = useRefForm({
    workgroup: {
      value: workgroups[0]?.ref ?? '',
      validate: isValidRef(workgroups, 'workgroup'),
    },
    handler: {
      value: '',
      validate: isValidRef(incidentHandlers, 'incident handler'),
    },
  });

  const handleSave = () => {
    if (form.validate()) {
      onSave?.([form.getValues()]);
    }
  };

  return (
    <FormDialog
      title="Add Facility Policy"
      open={open}
      onSave={handleSave}
      onCancel={() => onCancel?.()}
      clearItem={form.clear}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={12}>
          <Select
            label="Workgroup"
            options={getReferenceableOptions(workgroups)}
            field={form.state.workgroup}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Name" field={form.state.display_name} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Ref" field={form.state.id} />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Incident Handler"
            options={getReferenceableOptions(incidentHandlers)}
            field={form.state.handler}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
}
