export enum RRuleFrequency {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
}

export enum RRuleEnd {
  NEVER = 'NEVER',
  AFTER = 'AFTER',
  ON = 'ON',
}

export enum MonthlyRecurrenceType {
  MONTHDAY = 'MONTHDAY',
  DAY = 'DAY',
}

export enum YearlyRecurrenceType {
  MONTHDAY = 'MONTHDAY',
  POS = 'POS',
}

export interface RRuleKeyValue extends Record<string, string | undefined> {
  FREQ?: string;
  BYMONTH?: string;
  BYMONTHDAY?: string;
  BYSETPOS?: string;
  BYDAY?: string;
  INTERVAL?: string;
  UNTIL?: string;
  COUNT?: string;
}

export interface InputRRuleValue {
  rrule: string;
  dtstart: string;
}
