import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from '@mui/material';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import {
  Designation,
  DesignationProfile,
  EscalationTree,
  Workgroup,
} from '../../types';
import { DataTable, TableSchema } from '../../components/DataTable';
import { getRefName } from '../../utils/referenceable';
import { DesignationProfileFormDialog } from '../../components/forms/DesignationProfileFormDialog';

interface EscalationTreeSectionProps {
  escalationTree: EscalationTree;
  designations: ReferenceStore<Designation>;
  workgroups: ReferenceStore<Workgroup>;
  onDelete?: () => void;
  onAddProfile?: (...profiles: DesignationProfile[]) => void;
  onDeleteProfile?: (profile: DesignationProfile, index: number) => void;
}

export function EscalationTreeSection(props: EscalationTreeSectionProps) {
  const {
    escalationTree,
    designations,
    workgroups,
    onAddProfile,
    onDeleteProfile,
    onDelete,
  } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<DesignationProfile> = {
    designation: {
      header: 'Designation',
    },
    start_minutes: {
      header: 'Time to start calling',
    },
    personal_phone_policy: {
      header: 'Phone Policy',
    },
  };

  const mapped = escalationTree.designation_profiles.map((item) => ({
    ...item,
    designation: getRefName(item.designation, designations),
  }));

  const workgroupName = getRefName(escalationTree.workgroup, workgroups);

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title={`${escalationTree.display_name} (${escalationTree.id})`}
            subheader={workgroupName}
            action={(
              <IconButton color="primary" onClick={() => onDelete?.()}>
                <DeleteIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <DesignationProfileFormDialog
              designations={designations.references}
              open={isOpen}
              onCancel={() => setOpen(false)}
              onSave={(profiles) => {
                onAddProfile?.(...profiles);
                setOpen(false);
              }}
            />
            <DataTable
              inPaper={false}
              schema={schema}
              data={mapped}
              hint="No Designation Profiles Created"
              onAdd={() => setOpen(true)}
              onDelete={onDeleteProfile}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
