import {
  FormField,
  NCWell,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import {
  EntityConfigIncidentTransferMode,
  EntityConfigResourceIncidentVersion,
  EntityConfigResourceType,
  TeamFormationOption,
} from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function IncidentVersionForm({
  entityId,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const getEmptyTeamFormationOption = (): TeamFormationOption => ({
    _roles_ref_list: [],
    _target_role_ref: undefined,
  });

  const {
    data: dataValue,
  } = resourceData as EntityConfigResourceIncidentVersion ?? { data: {} };
  const {
    team_formation_options: teamFormationOptionsValue,
    transfer_mode: transferModeValue,
  } = dataValue ?? {};
  const {
    call_scheduled_users: callScheduledUsersValue,
    call_selected_roles: callSelectedRolesValue,
    call_selected_users: callSelectedUsersValue,
  } = teamFormationOptionsValue ?? {};
  const transferMode: FormField<EntityConfigIncidentTransferMode | ''> = {
    name: 'transferMode',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: transferModeValue ?? '',
  };
  const callSelectedUsersOptions: FormField<TeamFormationOption> = {
    name: 'callSelectedUsersOptions',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: callSelectedUsersValue ?? getEmptyTeamFormationOption(),
  };
  const callSelectedRolesOptions: FormField<TeamFormationOption> = {
    name: 'callSelectedRolesIncidentRoleIds',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: callSelectedRolesValue ?? getEmptyTeamFormationOption(),
  };
  const callScheduledUsersOptions: FormField<TeamFormationOption> = {
    name: 'callScheduledUsersIncidentRoleIds',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: callScheduledUsersValue ?? getEmptyTeamFormationOption(),
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    transferMode,
    callSelectedUsersOptions,
    callSelectedRolesOptions,
    callScheduledUsersOptions,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        transfer_mode: formState.transferMode.value,
        team_formation_options: {
          call_selected_users: callSelectedUsersOptions,
          call_selected_roles: callSelectedRolesOptions,
          call_scheduled_users: callScheduledUsersOptions,
        },
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />

      {[
        {
          label: 'Call Selected Users',
          value: formState.callSelectedUsersOptions.value,
          name: formState.callSelectedUsersOptions.name,
        },
        {
          label: 'Call Scheduled Users',
          value: formState.callScheduledUsersOptions.value,
          name: formState.callScheduledUsersOptions.name,
        },
        {
          label: 'Call Selected Roles',
          value: formState.callSelectedRolesOptions.value,
          name: formState.callSelectedRolesOptions.name,
        },
      ].map(({
        label,
        value: {
          _roles_ref_list: rolesRefList,
          _target_role_ref: targetRole,
        },
        name,
      }) => (
        <div key={name}>
          <p className="nc-l-mb_utilities_25_mobile nc-l-mt_200_mobile nc-t-body_medium_mobile nc-t-grey_900">{label}</p>
          <NCWell>
            <ConfigVisualEditorResourceRefControl
              label="Roles"
              name={`roles-${name}`}
              multiple
              resources={resources}
              resourceType={EntityConfigResourceType.INCIDENT_ROLES}
              entityId={entityId}
              onChange={(event) => onFormChange(
                formState.callSelectedUsersOptions.name,
                {
                  _target_role_ref: targetRole,
                  _roles_ref_list: Array.from(event.target.options)
                    .filter((option) => option.selected)
                    .map((option) => ({
                      key: option.value,
                      type: EntityConfigResourceType.INCIDENT_ROLES,
                    })),
                },
              )}
              value={rolesRefList?.map((ref) => ref.key) ?? []}
            />
            <ConfigVisualEditorResourceRefControl
              label="Target Role"
              className="nc-l-mt_200_mobile"
              name={`target-${name}`}
              resources={resources}
              resourceType={EntityConfigResourceType.INCIDENT_ROLES}
              entityId={entityId}
              onChange={(event) => onFormChange(
                formState.callSelectedUsersOptions.name,
                {
                  _roles_ref_list: rolesRefList,
                  _target_role_ref: {
                    key: event.target.value,
                    type: EntityConfigResourceType.INCIDENT_ROLES,
                  },
                },
              )}
              value={targetRole?.key ?? []}
            />
          </NCWell>
        </div>
      ))}
    </>
  );
}
