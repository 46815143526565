import { UnauthorizedError } from '@daupler/nexus-components';
import { useAuthentication } from '../hooks/useAuthentication';

export function ProtectedRoute({
  children,
}: React.PropsWithChildren) {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    throw new UnauthorizedError();
  }

  return children;
}
