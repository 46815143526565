import { NCInputText } from '@daupler/nexus-components';

type ConfigVisualEditorFieldJsonProps = {
  label: string;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value: string;
};

export function ConfigVisualEditorFieldJson({
  label,
  name,
  onChange,
  value,
}: ConfigVisualEditorFieldJsonProps) {
  return (
    <NCInputText
      multiline
      rows={3}
      label={label}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
}
