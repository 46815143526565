import { useState } from 'react';
import {
  FormField,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { DispatchConditionShiftCheck, EntityConfigResourceDispatchCondition, EntityConfigResourceType } from '../../types/EntityConfig';
import { InputList } from '../InputList';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function DispatchConditionForm({
  entityId,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const getEmptyDispatchConditionShiftCheck = () => ({
    id: '',
    scheduleId: '',
    shiftId: '',
    _schedule_ref: undefined,
    _shift_ref: undefined,
    options: {},
  });
  const [
    newDispatchConditionShiftCheck,
    setNewDispatchConditionShiftCheck,
  ] = useState<DispatchConditionShiftCheck>(getEmptyDispatchConditionShiftCheck());

  const {
    data: {
      shift_checks: shiftChecksValue,
    },
  } = resourceData as EntityConfigResourceDispatchCondition ?? { data: {} };
  const shiftChecks: FormField<DispatchConditionShiftCheck[]> = {
    name: 'shiftChecks',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: shiftChecksValue ?? [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    shiftChecks,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        shift_checks: formState.shiftChecks.value,
      },
    },
  });

  const addShiftCheck = () => {
    onFormChange(
      formState.shiftChecks.name,
      [
        ...formState.shiftChecks.value,
        newDispatchConditionShiftCheck,
      ],
    );
  };
  const removeShiftCheck = (data: DispatchConditionShiftCheck[]) => {
    onFormChange(
      formState.shiftChecks.name,
      data,
    );
  };

  const {
    _shift_ref: shiftRef,
    _schedule_ref: scheduleRef,
  } = newDispatchConditionShiftCheck;

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />

      <InputList<DispatchConditionShiftCheck>
        data={formState.shiftChecks.value}
        label="Shift Checks"
        onAdd={addShiftCheck}
        onRemove={removeShiftCheck}
        renderItem={(shiftCheck) => (
          <ul>
            <li>
              Schedule:
              {' '}
              {resources.find(({ resource }) => {
                const { _schedule_ref: ref } = shiftCheck;
                return resource.type === EntityConfigResourceType.SCHEDULES
                  && resource.key === ref?.key;
              })?.resource.display_name}
            </li>
            <li>
              Shift:
              {' '}
              {resources.find(({ resource }) => {
                const { _shift_ref: ref } = shiftCheck;
                return resource.type === EntityConfigResourceType.SHIFTS
                  && resource.key === ref?.key;
              })?.resource.display_name}
            </li>
          </ul>
        )}
        placeholder="No Shift Checks here yet!"
      >
        <ConfigVisualEditorResourceRefControl
          label="Schedule"
          name="rule-schedule"
          className="nc-l-mt_200_mobile"
          onChange={(event) => setNewDispatchConditionShiftCheck((state) => ({
            ...state,
            scheduleId: event.target.id,
          }))}
          value={scheduleRef?.key ?? ''}
          resources={resources}
          resourceType={EntityConfigResourceType.SCHEDULES}
          entityId={entityId}
        />
        <ConfigVisualEditorResourceRefControl
          label="Shift"
          name="rule-shift"
          className="nc-l-mt_200_mobile"
          onChange={(event) => setNewDispatchConditionShiftCheck((state) => ({
            ...state,
            playbookId: event.target.id,
          }))}
          value={shiftRef?.key ?? ''}
          resources={resources}
          resourceType={EntityConfigResourceType.SHIFTS}
          entityId={entityId}
        />
      </InputList>
    </>
  );
}
