import { DispatchActionProps } from './resource-form-types';
import { EntityConfigResourceDispatchActionDispatchActionEscalateParams, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function DispatchActionFormActionEscalate({
  entityId,
  onChange,
  value,
  resources,
}: DispatchActionProps) {
  const {
    _tree_id_ref: treeIdRef,
  } = value as EntityConfigResourceDispatchActionDispatchActionEscalateParams ?? {};
  return (
    <ConfigVisualEditorResourceRefControl
      label="Escalation Tree"
      name="close_action-tree"
      resources={resources}
      resourceType={EntityConfigResourceType.ESCALATION_TREES}
      entityId={entityId}
      value={treeIdRef?.key ?? ''}
      onChange={(event) => onChange({
        ...value as EntityConfigResourceDispatchActionDispatchActionEscalateParams,
        _tree_id_ref: {
          key: event.target.value,
          type: EntityConfigResourceType.ESCALATION_TREES,
        },
      })}
    />
  );
}
