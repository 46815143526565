import {
  Button,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import {
  Designation,
  DesignationProfile,
  EscalationTree,
  Workgroup,
} from '../../types';
import { EscalationTreeFormDialog } from '../../components/forms/EscalationTreesFormDialog';
import { EscalationTreeSection } from './EscalationTreeSection';

export interface EscalationTreePageProps {
  escalationTrees: ReferenceStore<EscalationTree>;
  designations: ReferenceStore<Designation>;
  workgroups: ReferenceStore<Workgroup>;
}

export function EscalationTreePage(props: EscalationTreePageProps) {
  const { escalationTrees, designations, workgroups } = props;
  const [isOpen, setOpen] = useState(false);

  const renderTree = (tree: EscalationTree) => {
    const onAddProfile = (...profiles: DesignationProfile[]) => {
      escalationTrees.updateReferences({
        ...tree,
        designation_profiles: [...tree.designation_profiles, ...profiles],
      });
    };

    const onDeleteProfile = (profile: DesignationProfile, index: number) => {
      escalationTrees.updateReferences({
        ...tree,
        designation_profiles: [
          ...tree.designation_profiles.filter((item, i) => index !== i),
        ],
      });
    };

    const onDelete = () => {
      escalationTrees.deleteReferences(tree);
    };

    return (
      <EscalationTreeSection
        escalationTree={tree}
        designations={designations}
        workgroups={workgroups}
        onAddProfile={onAddProfile}
        onDeleteProfile={onDeleteProfile}
        onDelete={onDelete}
      />
    );
  };

  const renderTrees = () => {
    if (escalationTrees.references.length <= 0) {
      return (
        <Grid item xs={12} marginTop="8px" style={{ opacity: '0.4' }}>
          <Typography variant="body1">No Escalation Trees Created</Typography>
        </Grid>
      );
    }
    return escalationTrees.references.map(renderTree);
  };

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          <EscalationTreeFormDialog
            workgroups={workgroups.references}
            open={isOpen}
            onSave={(items) => {
              escalationTrees.addReferences(...items);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
          <Typography variant="h3" align="left">
            Escalation Trees
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => setOpen(true)}>
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      {renderTrees()}
    </Grid>
  );
}
