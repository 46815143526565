import { PropsWithChildren } from 'react';
import { ToastContext, useProvideToast } from '../hooks/useToasts';

export function ProvideToast({ children }: PropsWithChildren<{}>) {
  const toast = useProvideToast();
  return (
    <ToastContext.Provider value={toast}>
      {children}
    </ToastContext.Provider>
  );
}
