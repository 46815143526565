import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigResourceServiceList, EntityConfigServiceListServiceType, EntityConfigServiceListType } from '../../types/EntityConfig';

export function ServiceListForm({
  entityId,
  onChange,
  onValidate,
  resourceData,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      list_type: listTypeValue,
      service_type: serviceTypeValue,
    },
  } = resourceData as EntityConfigResourceServiceList ?? { data: {} };
  const serviceType: FormField<EntityConfigServiceListServiceType | ''> = {
    name: 'serviceType',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceTypeValue ?? '',
  };
  const listType: FormField<EntityConfigServiceListType | ''> = {
    name: 'listType',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: listTypeValue ?? '',
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    serviceType,
    listType,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        service_type: formState.serviceType.value,
        list_type: formState.listType.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <NCSelect
        label="Service Type"
        className="nc-l-mt_200_mobile"
        name={formState.serviceType.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(EntityConfigServiceListServiceType).map((type) => ({
            label: type,
            value: type,
          })),
        ]}
        onChange={(event) => onFormChange(formState.serviceType.name, event.target.value)}
        value={formState.serviceType.value}
      />
      <NCSelect
        label="List Type"
        className="nc-l-mt_200_mobile"
        name={formState.listType.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(EntityConfigServiceListType).map((type) => ({
            label: type,
            value: type,
          })),
        ]}
        onChange={(event) => onFormChange(formState.listType.name, event.target.value)}
        value={formState.listType.value}
      />
    </>
  );
}
