import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MUISelect, {
  SelectChangeEvent,
  SelectProps as MUISelectProps,
} from '@mui/material/Select';
import React, { useRef } from 'react';
import { FieldState } from '../hooks/useField';

export type SelectProps<T> = {
  options: SelectOption<T>[];
  label: string;
  helperText?: string;
  field?: FieldState<T>;
} & MUISelectProps<T>;

export interface SelectOption<T> {
  key?: string;
  name?: string;
  value: T;
}

export function Select<T extends string | number>(props: SelectProps<T>) {
  const {
    options,
    label,
    helperText,
    value,
    onChange,
    error,
    field,
    ...parentProps
  } = props;
  const labelId = useRef(Math.random().toString());

  const handleChange = (
    event: SelectChangeEvent<T>,
    child: React.ReactNode,
  ) => {
    if (onChange != null) {
      onChange(event, child);
    } else if (field != null) {
      field.onChange(event.target.value as T);
    }
  };

  const resolvedError = error ?? !(field?.isValid ?? true);
  const resolvedHelperText = helperText ?? field?.invalidMessage;

  return (
    <FormControl fullWidth error={resolvedError}>
      <InputLabel id={labelId.current}>{label}</InputLabel>
      <MUISelect
        fullWidth
        labelId={labelId.current}
        label={label}
        value={value ?? field?.value}
        onChange={handleChange}
        error={resolvedError}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...parentProps}
      >
        {options.map((option) => (
          <MenuItem key={option.key ?? `${option.value}`} value={option.value}>
            {option.name ?? `${option.value}`}
          </MenuItem>
        ))}
      </MUISelect>
      {resolvedHelperText && resolvedHelperText !== null && resolvedHelperText !== '' ? (
        <FormHelperText>{resolvedHelperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
