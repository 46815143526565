import {
  EntityConfigResourceDispatchActionDispatchActionRunPolicyParams,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { DispatchActionProps } from './resource-form-types';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function DispatchActionFormRunPolicy({
  entityId,
  onChange,
  value,
  resources,
}: DispatchActionProps) {
  const {
    _policy_id_ref: policyIdRef,
  } = value as EntityConfigResourceDispatchActionDispatchActionRunPolicyParams ?? {};
  return (
    <ConfigVisualEditorResourceRefControl
      label="Dispatch Policy"
      name="multi_ack_transitional-dispatch_policy"
      resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
      resources={resources}
      entityId={entityId}
      value={policyIdRef?.key ?? ''}
      onChange={(event) => onChange({
        ...value as EntityConfigResourceDispatchActionDispatchActionRunPolicyParams,
        _policy_id_ref: {
          key: event.target.value,
          type: EntityConfigResourceType.DISPATCH_POLICIES,
        },
      } as EntityConfigResourceDispatchActionDispatchActionRunPolicyParams)}
    />
  );
}
