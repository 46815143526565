import { FormField, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigRef, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function FacilityPolicyForm({
  entityId,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const incidentVersionRef: FormField<EntityConfigRef | undefined> = {
    name: 'incidentVersionId',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: undefined,
  };
  const dispatchPolicyRef: FormField<EntityConfigRef | undefined> = {
    name: 'dispatchPolicyRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: undefined,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    incidentVersionRef,
    dispatchPolicyRef,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        _incident_version_ref: formState.incidentVersionRef.value,
        _dispatch_policy_ref: formState.dispatchPolicyRef.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <ConfigVisualEditorResourceRefControl
        label="Incident Version"
        className="nc-l-mt_200_mobile"
        name={formState.incidentVersionRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.INCIDENT_VERSIONS}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.incidentVersionRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.INCIDENT_VERSIONS,
          },
        )}
        value={formState.incidentVersionRef.value?.key ?? ''}
      />
      <ConfigVisualEditorResourceRefControl
        label="Dispatch Policy"
        className="nc-l-mt_200_mobile"
        name={formState.dispatchPolicyRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.dispatchPolicyRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.DISPATCH_POLICIES,
          },
        )}
        value={formState.dispatchPolicyRef.value?.key ?? ''}
      />
    </>
  );
}
