import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigModule } from '../types/EntityConfig';
import { ConfigVisualEditorModuleModal } from '../components/ConfigVisualEditorModuleModal';

export function EntitiesAddModuleRoute() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    modules,
    saveEntityConfig,
    setIsSaving,
    entity,
  } = useEntityConfigEditor();

  const handleSubmit = async (module: EntityConfigModule) => {
    modules.add(module);
    setIsSaving(true);
    saveEntityConfig({ background: true });
    setIsSaving(false);
  };

  if (!entity) { return null; }
  return (
    <ConfigVisualEditorModuleModal
      entityId={entity?.id ?? ''}
      onClose={() => navigate(`/config/entities/${entity.id}?${searchParams.toString()}`)}
      onSubmit={handleSubmit}
      params={params.data}
      resources={resources.data}
    />
  );
}
