import {
  FormField,
  NCButton,
  NCInputText,
  useForm,
} from '@daupler/nexus-components';
import { useState } from 'react';
import {
  EntityConfigModule,
  EntityConfigModuleType,
  EntityConfigResourceType,
} from '../types/EntityConfig';
import { logger } from '../utils/logger';
import { EditorEntityModule, EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorModuleForms } from './ConfigVisualEditorModuleForms';
import './ConfigVisualEditorModuleModal.css';

enum View {
  CHOOSE_MODULE = 'CHOOSE_MODULE',
  CONFIGURE_MODULE = 'CONFIGURE_MODULE',
}

type ConfigVisualEditorModuleModalProps = {
  entityId: string;
  onClose: () => void;
  onSubmit: (resource: EntityConfigModule) => Promise<void>;
  module?: EditorEntityModule;
  params: EditorEntityParam[];
  resources: EditorEntityResource[];
};

export function ConfigVisualEditorModuleModal({
  entityId,
  onClose,
  onSubmit,
  module,
  params,
  resources,
}: ConfigVisualEditorModuleModalProps) {
  const [view, setView] = useState(module?.module.module_type
    ? View.CONFIGURE_MODULE
    : View.CHOOSE_MODULE);

  const type: FormField<EntityConfigModuleType> = {
    invalidMessage: '',
    name: 'type',
    validate: (value) => !!value,
    validMessage: '',
    value: module?.module.module_type ?? '' as EntityConfigModuleType,
    isValid: !!module?.module.module_type,
  };
  const moduleData: FormField<Omit<EntityConfigModule, 'module_type'> | null> = {
    invalidMessage: '',
    name: 'moduleData',
    validate: (_, state) => state.moduleData.isValid ?? false,
    validMessage: '',
    value: module?.module as EntityConfigModule ?? null,
  };

  const {
    formState,
    isFormValid,
    onChange,
    validateField,
    validateForm,
  } = useForm({ moduleData, type });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    validateField(formState.type.name);
    if (view === View.CHOOSE_MODULE && formState.type.isValid) {
      setView(View.CONFIGURE_MODULE);
      return;
    }

    validateForm();
    if (!isFormValid()) { return; }

    try {
      await onSubmit({
        module_type: formState.type.value,
        ...(formState.moduleData.value ?? {}),
      } as EntityConfigModule);
      onClose();
    } catch (err) {
      logger.error((err as Error).message, err);
    }
  };

  const handleCancel = () => {
    if (module?.module.module_type || view === View.CHOOSE_MODULE) {
      onClose();
    } else {
      setView(View.CHOOSE_MODULE);
    }
  };

  const canSubmit = () => {
    if (view === View.CHOOSE_MODULE) {
      return formState.type.isValid;
    }
    return isFormValid();
  };

  const [moduleSearchFilter, setModuleSearchFilter] = useState('');

  return (
    <form onSubmit={handleSubmit} className="config_visual_editor_module_modal">
      <div className="config_visual_editor_module_modal__body">
        {view === View.CHOOSE_MODULE ? (
          <>
            <div className="nc-flex nc-flex--align_end nc-flex--justify_between nc-l-mt_100_mobile">
              <p className="nc-t-h5_medium_mobile">Choose Module</p>
              <NCInputText
                label="Search for Module"
                labelHidden
                value={moduleSearchFilter}
                onChange={(event) => { setModuleSearchFilter(event.target.value); }}
                name="module-search"
                tag={(<i className="fa-solid fa-search fa-fw" />)}
              />
            </div>
            <div className="nc-l-mt_200_mobile config_visual_editor_resource_modal__grid">
              {Object.values(EntityConfigModuleType)
                .filter((moduleType) => moduleType.toLowerCase().includes(moduleSearchFilter))
                .map((resourceType) => (
                  <div key={resourceType} className="config_visual_editor_resource_modal__item">
                    <p className="nc-t-body_medium_mobile">
                      {resourceType}
                    </p>
                    <p className="nc-t-sub_text_regular_mobile">
                      description
                    </p>
                    <NCButton
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                      appearance={NCButton.appearances.OUTLINE}
                      color={NCButton.colors.PRIMARY}
                      active={formState.type.value === resourceType}
                      className="nc-l-mt_100_mobile"
                      disabled={formState.type.value && formState.type.value !== resourceType}
                      onClick={() => {
                        if (formState.type.value === resourceType) {
                          onChange(formState.type.name, '' as EntityConfigModuleType);
                        } else {
                          onChange(formState.type.name, resourceType);
                        }
                      }}
                    >
                      {formState.type.value === resourceType
                        ? 'Unselect'
                        : 'Select'}
                    </NCButton>
                  </div>
                ))}
            </div>
          </>
        ) : null}
        {view === View.CONFIGURE_MODULE ? (
          <div>
            <ConfigVisualEditorModuleForms
              entityId={entityId}
              onChange={(value) => {
                onChange(
                  formState.moduleData.name,
                  value,
                );
              }}
              onValidate={(result) => {
                validateField(formState.moduleData.name, result);
              }}
              moduleType={formState.type.value}
              params={params}
              module={formState.moduleData.value}
              resources={resources}
              workgroups={resources.filter(
                (workgroup) => workgroup
                  .resource.type === EntityConfigResourceType.ESCALATION_TREES,
              )}
            />
          </div>
        ) : null}
      </div>

      <div className="config_visual_editor_module_modal__footer nc-flex nc-flex--align_center nc-flex--justify_between nc-l-pa_utilities_225_mobile">
        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          color={NCButton.colors.GREY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={handleCancel}
        >
          {view === View.CHOOSE_MODULE || module?.module.module_type ? 'Cancel' : 'Back'}
        </NCButton>
        <NCButton
          appearance={NCButton.appearances.SOLID}
          color={NCButton.colors.PRIMARY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          type="submit"
          disabled={!canSubmit()}
        >
          {view === View.CHOOSE_MODULE ? 'Next' : 'Save'}
        </NCButton>
      </div>
    </form>
  );
}
