import { RRuleKeyValue } from '../components/InputRRule/InputRRule';

export const getKeyValueFromRRule = (value: string): RRuleKeyValue => value?.split(';')
  .reduce((result, keyValString) => ({
    ...result,
    [keyValString.split('=')[0]]: keyValString.split('=')[1],
  }), {} as RRuleKeyValue) ?? {};

export const rruleUntilToDate = (value: string): Date => {
  const [date, time] = value.split('T');
  const formattedDate = [
    ...date.split('').slice(0, 4),
    '-',
    ...date.split('').slice(4, 6),
    '-',
    ...date.split('').slice(6, 8),
  ].join('');
  const formattedTime = [
    ...time.split('').slice(0, 2),
    ':',
    ...time.split('').slice(2, 4),
    ':',
    ...time.split('').slice(4, 7),
  ].join('');
  return new Date(`${formattedDate}T${formattedTime}`);
};

export const dateToRRuleUntil = (date: Date): string => {
  const [isoDate] = date.toISOString().split('T');
  return `${new Date(isoDate).toISOString()
    .split('-')
    .join('')
    .split(':')
    .join('')
    .split('.')[0]}Z`;
};

export const keyValueToRRuleString = (keyValue: RRuleKeyValue): string => Object
  .entries(keyValue)
  .map(([key, val]) => `${key}=${val}`)
  .reduce((result, string, i, arr) => `${result}${string}${i < arr.length - 1 ? ';' : ''}`, '');
