import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { useState } from 'react';
import { ConfigStateValues } from '../hooks/useConfigState';
import { MainPage } from '../views/yaml-builder/Main';
import { Navbar } from '../components/Navbar';

export function YamlBuilderRoute() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#284360',
      },
      secondary: {
        main: '#639084',
      },
    },
  });

  const [file, setFile] = useState<ConfigStateValues | undefined>(undefined);
  const [fileName, setFileName] = useState('');

  return (
    <>
      <Navbar title="YAML Config Tool" />
      <div style={{ padding: '24px' }}>
        <ThemeProvider theme={theme}>
          <MainPage
            key={fileName}
            onFileLoaded={(name, values) => {
              setFileName(name);
              setFile(values);
            }}
            values={file}
          />
        </ThemeProvider>
      </div>
    </>
  );
}
