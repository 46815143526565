import { NCSelect } from '@daupler/nexus-components';
import { ComponentProps } from 'react';
import {
  EntityConfigParam,
  EntityConfigParamImageValue,
  EntityConfigParamPhoneNumberValue,
  EntityConfigParamStringValue,
  EntityConfigParamType,
} from '../types/EntityConfig';
import { EditorEntityParam } from '../hooks/useEntityConfigEditor';

type ConfigVisualEditorParamControlProps = Omit<ComponentProps<typeof NCSelect>, 'options'> & {
  params: EditorEntityParam[];
  paramType: EntityConfigParamType;
};

export function ConfigVisualEditorParamControl(props: ConfigVisualEditorParamControlProps) {
  const {
    params,
    paramType,
  } = props;

  const getParamDisplayValue = (param: EntityConfigParam) => {
    const { value } = param;
    switch (paramType) {
      case EntityConfigParamType.EXTENT:
        return '';
      case EntityConfigParamType.IMAGE:
        return (value as EntityConfigParamImageValue).filename;
      case EntityConfigParamType.JSON:
        return '';
      case EntityConfigParamType.PHONE_NUMBER:
        return (value as EntityConfigParamPhoneNumberValue).phone_number;
      case EntityConfigParamType.STRING:
        return (value as EntityConfigParamStringValue).text;
      default:
        return '';
    }
  };

  return (
    <NCSelect
      // 2024-12-11 pdb: passing through props from a wrapper is OK imo
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      options={[
        { label: 'Choose one...', value: '' },
        ...params
          .filter(({ param: { type } }) => paramType === type)
          .map(({ id, param }) => ({
            label: `${param.key}: ${getParamDisplayValue(param)}`,
            value: id,
          })),
      ]}
    />
  );
}
