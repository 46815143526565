import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { DataTable, TableSchema } from '../../components/DataTable';
import { ScheduleFormDialog } from '../../components/forms/ScheduleFormDialog';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { Schedule } from '../../types';

export interface SchedulesPageProps {
  schedules: ReferenceStore<Schedule>;
}

export function SchedulesPage(props: SchedulesPageProps) {
  const { schedules } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<Schedule> = {
    id: {
      header: 'Ref',
    },
    display_name: {
      header: 'Name',
    },
    description: {
      header: 'Description',
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Schedules
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ScheduleFormDialog
          open={isOpen}
          onSave={(items) => {
            schedules.addReferences(...items);
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
        />
        <DataTable
          schema={schema}
          data={schedules.references}
          hint="No Schedules Created"
          onDelete={(item) => schedules.deleteReferences(item)}
          onAdd={() => setOpen(true)}
        />
      </Grid>
    </Grid>
  );
}
