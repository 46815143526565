function getSqDist(p1: { x: number; y: number; }, p2: { x: number; y: number; }) {
  const dx = p1.x - p2.x;
  const dy = p1.y - p2.y;

  return dx * dx + dy * dy;
}

// square distance from a point to a segment
function getSqSegDist(
  p: { x: number; y: number; },
  p1: { x: number; y: number; },
  p2: { x: number; y: number; },
) {
  let { x } = p1;
  let { y } = p1;
  let dx = p2.x - x;
  let dy = p2.y - y;

  if (dx !== 0 || dy !== 0) {
    const t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy);
    if (t > 1) {
      x = p2.x;
      y = p2.y;
    } else if (t > 0) {
      x += dx * t;
      y += dy * t;
    }
  }

  dx = p.x - x;
  dy = p.y - y;

  return dx * dx + dy * dy;
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points: string | any[], sqTolerance: number) {
  let prevPoint = points[0];
  const newPoints = [prevPoint];
  let point;

  for (let i = 1, len = points.length; i < len; i += 1) {
    point = points[i];

    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }

  if (prevPoint !== point) {
    newPoints.push(point);
  }

  return newPoints;
}

function simplifyDPStep(
  points: { x: number; y: number; }[],
  first: number,
  last: number,
  sqTolerance: number,
  simplified: { x: number; y: number; }[],
) {
  let maxSqDist = sqTolerance;
  let index: number = first + 1;

  for (let i = first + 1; i < last; i += 1) {
    const sqDist = getSqSegDist(points[i], points[first], points[last]);

    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(points[index]);
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points: { x: number; y: number; }[], sqTolerance: number) {
  const last = points.length - 1;

  const simplified = [points[0]];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(points[last]);

  return simplified;
}

// both algorithms combined for awesome performance
export function simplify(
  points: { x: number, y: number }[],
  tolerance?: number,
  highestQuality?: boolean,
) {
  let simplifiedPoints = [...points];
  if (simplifiedPoints.length <= 2) {
    return points;
  }

  const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

  simplifiedPoints = highestQuality
    ? simplifiedPoints
    : simplifyRadialDist(simplifiedPoints, sqTolerance);
  simplifiedPoints = simplifyDouglasPeucker(simplifiedPoints, sqTolerance);

  return simplifiedPoints;
}

export function simplifyLL(
  gll: { lat: number; lng: number; }[],
  tolerance?: number,
  highestQuality?: boolean,
): { lat: number; lng: number }[] {
  const simpPts = simplify(
    gll.map((_) => ({ x: _.lng, y: _.lat })),
    tolerance,
    highestQuality,
  );
  return simpPts.map((_) => ({ lat: _.y, lng: _.x }));
}

export function simplifyGLL(
  gll: google.maps.LatLng[],
  tolerance?: number,
  highestQuality?: boolean,
) {
  const simpPts = simplify(
    gll.map((_) => ({ x: _.lng(), y: _.lat() })),
    tolerance,
    highestQuality,
  );
  return simpPts.map((_) => new google.maps.LatLng({ lat: _.y, lng: _.x }));
}
