import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource } from '../types/EntityConfig';

export function EntitiesAddResourceRoute() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    saveEntityConfig,
    setIsSaving,
    entity,
  } = useEntityConfigEditor();

  const handleSubmit = async (resource: EntityConfigResource) => {
    resources.add(resource);
    setIsSaving(true);
    saveEntityConfig({ background: true });
    setIsSaving(false);
  };

  if (!entity) { return null; }

  return (
    <ConfigVisualEditorResourceModal
      entityId={entity?.id ?? ''}
      onClose={() => navigate(`/config/entities/${entity.id}?${searchParams.toString()}`)}
      onSubmit={handleSubmit}
      params={params.data}
      resources={resources.data}
    />
  );
}
