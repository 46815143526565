import { useEffect, useRef } from 'react';
import { EntityConfigAnyResource } from '../types/EntityConfig';
import { OnResourceChange } from '../components/resource-forms/resource-form-types';

export const useSyncResourceForm = ({
  isFormValid,
  onChange,
  onValidate,
  resourceData,
}: {
  isFormValid: () => boolean;
  onChange: OnResourceChange;
  onValidate: (result: boolean) => void;
  resourceData: Omit<EntityConfigAnyResource, 'type' | 'workgroup_internal_name'>;
}) => {
  const previousResourceData = useRef(JSON.stringify(resourceData));

  useEffect(() => {
    if (previousResourceData.current === JSON.stringify(resourceData)) {
      return;
    }
    onChange(resourceData);
    onValidate(isFormValid());
    previousResourceData.current = JSON.stringify(resourceData);
  }, [isFormValid, onChange, onValidate, resourceData]);

  return {};
};
