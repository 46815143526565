import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as R from 'ramda';
import { FieldOptions } from '../../hooks/useField';
import { useRefForm } from '../../hooks/useRefForm';
import {
  IncidentHandler,
  ServiceArea,
  ShutoffList,
  Utility,
  UtilityData,
  UtilityType,
  Workgroup,
} from '../../types';
import {
  generateId,
  getReferenceableOptions,
  isValidRef,
} from '../../utils/referenceable';

import { utilityTypeOptions } from '../../utils/utility';
import { or, required } from '../../utils/validators';
import { FormDialog, FormDialogBaseProps } from '../FormDialog';
import { Select, SelectOption } from '../Select';

export interface UtilityFormDialogProps
  extends FormDialogBaseProps<UtilityData> {
  workgroups: Workgroup[];
  incidentHandlers: IncidentHandler[];
  serviceAreas: ServiceArea[];
  shutoffLists: ShutoffList[];
  utilities: Utility[];
}
export function UtilityFormDialog(props: UtilityFormDialogProps) {
  const {
    open,
    onCancel,
    onSave,
    workgroups,
    incidentHandlers,
    serviceAreas,
    shutoffLists,
    utilities,
  } = props;

  const isNone = (value: string) => value === ' ';

  const createdTypes = utilities.map((u) => u.utility_type);

  const form = useRefForm({
    utility_type: {
      value: [],
      validate: required,
      onChange: (values) => {
        form.state.display_name.onChange(values.join(' '));
      },
    } as FieldOptions<UtilityType[]>,
    workgroup: {
      value: workgroups[0]?.ref ?? '',
      validate: isValidRef(workgroups, 'workgroup'),
    },
    default_handler: {
      value: incidentHandlers[0]?.ref ?? '',
      validate: isValidRef(incidentHandlers, 'incident handler'),
    },
    service_area: {
      value: ' ',
      validate: or(isNone, isValidRef(serviceAreas, 'service area')),
    },
    shutoff_list: {
      value: ' ',
      validate: or(isNone, isValidRef(shutoffLists, 'shutoff list')),
    },
  });

  const getValues = (): UtilityData[] => {
    const values = form.getValues();

    const baseValues: any = {
      ...R.omit(
        ['service_area', 'shutoff_list', 'utility_type', 'display_name', 'id'],
        values,
      ),
    };

    if (values.service_area !== ' ') {
      baseValues.service_area = values.service_area;
    }

    if (values.shutoff_list !== ' ') {
      baseValues.shutoff_list = values.shutoff_list;
    }

    return values.utility_type.map((type) => ({
      ...baseValues,
      utility_type: type,
      display_name: type,
      id: generateId(type),
    }));
  };

  const handleSave = () => {
    if (form.validate()) {
      onSave?.(getValues());
    }
  };

  const renderCheckBoxForType = (
    option: SelectOption<UtilityType>,
    index: number,
  ) => {
    const isChecked = form.state.utility_type.value.includes(option.value);
    const onChange = (checked: boolean) => {
      if (checked) {
        form.state.utility_type.onChange([
          ...form.state.utility_type.value,
          option.value,
        ]);
      } else {
        form.state.utility_type.onChange([
          ...form.state.utility_type.value.filter(
            (value) => value !== option.value,
          ),
        ]);
      }
    };

    const columns = 2;
    const perColumn = Math.ceil(utilityTypeOptions.length / columns);
    const maxCount = perColumn * columns - 1;
    const column = Math.floor(index / perColumn);
    const order = columns * index - maxCount * column;

    const color = createdTypes.includes(option.value)
      ? 'rgba(0,0,0,0.2)'
      : 'rgba(0,0,0,1)';

    return (
      <Grid
        item
        xs={12 / columns}
        order={order}
        style={{
          color,
        }}
      >
        <FormControlLabel
          label={option.name ?? option.value}
          control={(
            <Checkbox
              checked={isChecked}
              onChange={(_event, checked) => onChange(checked)}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <FormDialog
      onCancel={() => onCancel?.()}
      open={open}
      onSave={handleSave}
      title="Add Utility"
      clearItem={form.clear}
    >
      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography fontWeight="500">Utility Type</Typography>
          </Grid>
          {utilityTypeOptions.map(renderCheckBoxForType)}
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Workgroup"
            options={getReferenceableOptions(workgroups)}
            field={form.state.workgroup}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Default Handler"
            options={getReferenceableOptions(incidentHandlers)}
            field={form.state.default_handler}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Service Area"
            options={[
              ...getReferenceableOptions(serviceAreas),
              {
                value: ' ',
                name: 'None',
              },
            ]}
            field={form.state.service_area}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Shutoff List"
            options={[
              ...getReferenceableOptions(shutoffLists),
              {
                value: ' ',
                name: 'None',
              },
            ]}
            field={form.state.shutoff_list}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
}
