import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { RotationsFormDialog } from '../../components/forms/RotationsFormDialog';
import { DataTable, TableSchema } from '../../components/DataTable';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { Designation, Rotation } from '../../types';
import { getRefName } from '../../utils/referenceable';
import { rotationTypes } from '../../utils/rotations';

export interface RotationsPageProps {
  rotations: ReferenceStore<Rotation>;
  designations: ReferenceStore<Designation>;
}

export function RotationsPage(props: RotationsPageProps) {
  const { rotations, designations } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<Rotation> = {
    id: {
      header: 'Ref',
    },
    type: {
      header: 'Type',
      renderValue: (value: Rotation['type']) => {
        if (rotationTypes.includes(value)) {
          return value;
        }
        return <Typography color="error">{value}</Typography>;
      },
    },
    display_name: {
      header: 'Name',
    },
    apply_to_designations: {
      header: 'Designations',
    },
    description: {
      header: 'Description',
    },
    switch_time: {
      header: 'Switch Time',
    },
    dtstart: {
      header: 'Start Date',
    },
  };

  const mapDesignations = (rotation: Rotation): Rotation => ({
    ...rotation,
    apply_to_designations: rotation.apply_to_designations.map(
      (designationRef) => getRefName(designationRef, designations),
    ),
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Rotations
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RotationsFormDialog
          designations={designations.references}
          open={isOpen}
          onCancel={() => {
            setOpen(false);
          }}
          onSave={(items) => {
            rotations.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={rotations.references.map(mapDesignations)}
          onAdd={() => setOpen(true)}
          onDelete={(item) => rotations.deleteReferences(item)}
          hint="No Rotations Created"
        />
      </Grid>
    </Grid>
  );
}
