import { PartitionScheme } from '../types';
import { isIn } from './validators';

const schemeName = (scheme: string) => scheme.split('_').map((word) => word[0] + word.slice(1).toLowerCase()).join(' ');

export const partitionSchemeOptions = Object.values(PartitionScheme).map((scheme) => ({
  value: scheme,
  name: schemeName(scheme),
}));

export const isValidPartitionScheme = isIn(Object.values(PartitionScheme), 'partition scheme');
