export const getDayHours = () => new Array(24)
  .fill(undefined)
  .map((_, i) => `${i.toString().padStart(2, '0')}:00`);

export const getMonths = (locale: string = 'en-US'): { label: string; index: number; }[] => {
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    const date = new Date();
    date.setDate(1);
    date.setMonth(i);
    months.push({
      label: date.toLocaleString(locale, { month: 'long' }),
      index: i + 1,
    });
  }
  return months;
};

export const getDaysOfTheWeek = (locale: string = 'en-US'): { label: string; abbr: string; }[] => {
  const { format } = new Intl.DateTimeFormat(locale, { weekday: 'long' });
  const { format: formatAbbr } = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
  const days = [];
  for (let i = 0; i < 7; i += 1) {
    days.push({
      label: format(new Date(Date.UTC(2021, 5, i))),
      abbr: formatAbbr(new Date(Date.UTC(2021, 5, i))).substr(0, 2).toUpperCase(),
    });
  }
  return days;
};

export const getDayIndexFromAbbr = (dayAbbr: string, locale: string = 'en-US') => {
  const days = getDaysOfTheWeek(locale);
  return days.findIndex((day) => day.abbr === dayAbbr);
};

export const getDaysInMonth = (month: number, year: number): number => {
  const date = new Date(year, month, 0);
  return date.getDate();
};

export const getCurrentYear = (): number => new Date().getFullYear();

export const isoStringToInstant = (isoString: string) => {
  const parts = isoString.split('T');
  return parts[0];
};

export const isoStringToLocal = (isoString: string) => {
  const parts = isoString.split('Z');
  return parts[0];
};

export const getDayAbbrForISODate = (isoString: string, locale: string = 'en-US') => {
  const days = getDaysOfTheWeek(locale);
  return days[new Date(isoString).getDay()];
};
