import { useState } from 'react';
import { NCButton, NCInputText } from '@daupler/nexus-components';
import yaml from 'yaml';
import './ConfigVisualEditor.css';
import { EntityConfig } from '../types/EntityConfig';

type ConfigVisualEditorCodeProps = {
  configurationObject: EntityConfig | null;
  onYamlChange: (config: string) => void;
};

export function ConfigVisualEditorCode({
  configurationObject,
  onYamlChange,
}: ConfigVisualEditorCodeProps) {
  const [yamlValue, setYamlValue] = useState(yaml.stringify(configurationObject, { schema: 'yaml-1.1' }));

  return (
    <div className="config_visual_editor__text_editor">
      <NCInputText
        multiline
        rows={3}
        name="configuration-raw"
        hint=""
        value={yamlValue}
        className="config_visual_editor__code"
        onChange={(event) => {
          setYamlValue(event.target.value);
        }}
        label="Configuration Raw"
        labelHidden
      />
      <div className="nc-flex nc-flex--justify_end nc-l-pa_200_mobile">
        <NCButton
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={() => {
            onYamlChange(yamlValue);
          }}
        >
          Save
        </NCButton>
      </div>
    </div>
  );
}
