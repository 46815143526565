import { ChangeEvent, useEffect, useState } from 'react';
import { NCSelect } from '@daupler/nexus-components';
import { getKeyValueFromRRule } from '../../utils/rrule-util';
import { getDaysOfTheWeek } from '../../utils/datetime';

function InputRRuleMonthlyDay({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [pos, setPos] = useState(getKeyValueFromRRule(value).BYSETPOS ?? '1');
  const updatePos = (event: ChangeEvent<HTMLSelectElement>) => {
    setPos(event.target.value);
  };

  const [day, setDay] = useState(getKeyValueFromRRule(value).BYDAY ?? 'MO');
  const updateDay = (event: ChangeEvent<HTMLSelectElement>) => {
    setDay(event.target.value);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.BYSETPOS !== pos || parsedValue.BYDAY !== day) {
      onChange({ BYSETPOS: pos, BYDAY: day });
    }
  }, [day, onChange, pos, value]);

  return (
    <div className="d-flex d-flex--row">
      <NCSelect
        className="dl-mt--md"
        name="rrule-monthly-day-pos"
        label="Position"
        onChange={updatePos}
        options={[
          {
            label: 'First',
            value: '1',
          },
          {
            label: 'Second',
            value: '2',
          },
          {
            label: 'Third',
            value: '3',
          },
          {
            label: 'Fourth',
            value: '4',
          },
          {
            label: 'Last',
            value: '-1',
          },
        ]}
        value={pos}
      />
      <NCSelect
        className="dl-mt--md dl-ml--sm"
        name="rrule-monthly-day-day"
        label="Day"
        onChange={updateDay}
        options={getDaysOfTheWeek().map((weekday) => ({
          label: weekday.label,
          value: weekday.abbr,
        }))}
        value={day}
      />
    </div>
  );
}

export default InputRRuleMonthlyDay;
