import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { FieldState } from '../hooks/useField';

export type TextFieldProps = MuiTextFieldProps & {
  field?: FieldState<any>;
};

export function TextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    error,
    helperText,
    field,
    ...restProps
  } = props;
  return (
    <MuiTextField
      fullWidth
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      value={value ?? field?.value}
      onChange={onChange ?? field?.onChange.input}
      error={error ?? !(field?.isValid ?? true)}
      helperText={helperText ?? field?.invalidMessage}
    />
  );
}
