import { NCButton } from '@daupler/nexus-components';

type ConfigVisualEditorFieldImageProps = {
  onChange?: (publicUrl: string) => void;
  src?: string;
};

export function ConfigVisualEditorFieldImage({
  onChange,
  src,
}: ConfigVisualEditorFieldImageProps) {
  const onUpload = () => {
    const url = '';
    onChange?.(url);
  };
  return (
    <div>
      {src ? (
        <img src={src} alt="" />
      ) : null}
      <NCButton
        size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
        color={NCButton.colors.PRIMARY}
        width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
        onClick={onUpload}
      >
        Upload
      </NCButton>
    </div>
  );
}
