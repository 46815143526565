import clsx from 'clsx';
import './SegmentedControl.css';

type SegmentedControlProps = {
  className?: string;
  onChange: (value: string) => void;
  segments: { label: string; value: string }[];
  value: string;
};

export function SegmentedControl({
  className,
  onChange,
  segments,
  value,
}: SegmentedControlProps) {
  return (
    <div className={clsx('segmented_control', className)}>
      {segments.map((segment) => (
        <button
          key={segment.value}
          type="button"
          className={clsx('segmented_control__button', {
            'segmented_control__button--active': segment.value === value,
          })}
          onClick={() => onChange(segment.value)}
        >
          {segment.label}
        </button>
      ))}
    </div>
  );
}
