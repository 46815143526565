import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { DataTable, TableSchema } from '../../components/DataTable';
import { ServiceAreaFormDialog } from '../../components/forms/ServiceAreaFormDialog';
import { ReferenceStore } from '../../hooks/useReferenceStore';
import { ServiceArea } from '../../types';

export interface ServiceAreaPageProps {
  serviceAreas: ReferenceStore<ServiceArea>;
}
export function ServiceAreaPage(props: ServiceAreaPageProps) {
  const { serviceAreas } = props;
  const [isOpen, setOpen] = useState(false);

  const schema: TableSchema<ServiceArea> = {
    id: {
      header: 'Ref',
    },
    display_name: {
      header: 'Name',
    },
    filename: {
      header: 'File Name',
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          Service Areas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ServiceAreaFormDialog
          open={isOpen}
          onCancel={() => setOpen(false)}
          onSave={(items) => {
            serviceAreas.addReferences(...items);
            setOpen(false);
          }}
        />
        <DataTable
          schema={schema}
          data={serviceAreas.references}
          onAdd={() => setOpen(true)}
          onDelete={(item) => serviceAreas.deleteReferences(item)}
          hint="No Service Areas Created"
        />
      </Grid>
    </Grid>
  );
}
