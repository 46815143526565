import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource } from '../types/EntityConfig';

export function EntitiesEditResourceRoute() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    saveEntityConfig,
    setIsSaving,
    entity,
  } = useEntityConfigEditor();

  const { resourceId } = useParams();
  const [resourceType, key] = resourceId?.split('|') ?? [];
  const handleSubmit = async (resource: EntityConfigResource) => {
    resources.update(key, resource);
    setIsSaving(true);
    saveEntityConfig({ background: true });
    setIsSaving(false);
  };

  if (!entity) { return null; }
  return (
    <ConfigVisualEditorResourceModal
      key={resourceId}
      entityId={entity?.id ?? ''}
      onClose={() => navigate(`/config/entities/${entity.id}?${searchParams.toString()}`)}
      onSubmit={handleSubmit}
      params={params.data}
      resource={resources.data
        .find(({ id, resource: { type } }) => id === key && resourceType === type)}
      resources={resources.data}
    />
  );
}
