import { Fab } from '@mui/material';
import Grid from '@mui/material/Grid';
import UploadIcon from '@mui/icons-material/UploadFile';
import { ChangeEventHandler, useRef } from 'react';
import { ConfigStateValues, useConfigState } from '../../hooks/useConfigState';
import { generateConfig, readConfig } from '../../utils/config';
import { DesignationPage } from './DesignationPage';
import { DetailsPage } from './DetailsPage';
import { EscalationTreePage } from './EscalationTreePage';
import { FacilityPolicyPage } from './FacilityPolicyPage';
import { IncidentHandlerPage } from './IncidentHandlerPage';
import { RotationsPage } from './RotationsPage';
import { SchedulesPage } from './SchedulesPage';
import { ServiceAreaPage } from './ServiceAreaPage';
import { ServiceExtentPage } from './ServiceExtentPage';
import { ShutoffListPage } from './ShutoffListPage';
import { UtilitiesPage } from './UtilitiesPage';
import { WorkgroupPage } from './WorkgroupPage';

export interface MainPageProps {
  values?: ConfigStateValues;
  onFileLoaded: (name: string, config: ConfigStateValues) => void;
}

export function MainPage({ values, onFileLoaded }: MainPageProps) {
  const configState = useConfigState(values);
  const {
    detailsForm,
    serviceExtentForm,
    workgroups,
    serviceAreas,
    designations,
    rotations,
    escalationTrees,
    schedules,
    incidentHandlers,
    facilityPolicies,
    shutoffLists,
    utilities,
  } = configState;

  const downloadConfigFile = () => {
    const element = document.createElement('a');
    const file = new Blob([generateConfig(configState)], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'config.yaml';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  };

  const handleGenerateConfig = () => {
    const valid = [detailsForm.validate(), serviceExtentForm.validate()].reduce(
      (acc, curr) => acc && curr,
      true,
    );

    if (valid) {
      downloadConfigFile();
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOnUpload = () => {
    inputFile.current?.click();
  };

  const handleFileChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const configValues = readConfig(reader.result ?? '');
          if (configValues != null) {
            onFileLoaded(file.name, configValues);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <Grid container spacing={6}>
        <Grid item lg={6} xs={12}>
          <DetailsPage detailsForm={detailsForm} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <ServiceExtentPage form={serviceExtentForm} />
        </Grid>
        <Grid item xs={12}>
          <WorkgroupPage workgroups={workgroups} />
        </Grid>
        <Grid item xs={12}>
          <ServiceAreaPage serviceAreas={serviceAreas} />
        </Grid>
        <Grid item xs={12}>
          <DesignationPage
            workgroups={workgroups}
            designations={designations}
          />
        </Grid>
        <Grid item xs={12}>
          <RotationsPage rotations={rotations} designations={designations} />
        </Grid>
        <Grid item xs={12}>
          <EscalationTreePage
            escalationTrees={escalationTrees}
            designations={designations}
            workgroups={workgroups}
          />
        </Grid>
        <Grid item xs={12}>
          <SchedulesPage schedules={schedules} />
        </Grid>
        <Grid item xs={12}>
          <IncidentHandlerPage
            incidentHandlers={incidentHandlers}
            escalationTrees={escalationTrees}
            workgroups={workgroups}
          />
        </Grid>
        <Grid item xs={12}>
          <FacilityPolicyPage
            workgroups={workgroups}
            incidentHandlers={incidentHandlers}
            facilityPolicies={facilityPolicies}
          />
        </Grid>
        <Grid item xs={12}>
          <ShutoffListPage
            workgroups={workgroups}
            incidentHandlers={incidentHandlers}
            shutoffLists={shutoffLists}
          />
        </Grid>
        <Grid item xs={12}>
          <UtilitiesPage
            utilities={utilities}
            workgroups={workgroups}
            incidentHandlers={incidentHandlers}
            serviceAreas={serviceAreas}
            shutoffLists={shutoffLists}
          />
        </Grid>
        <Grid item xs={12} height="72px" />
      </Grid>
      <Fab
        style={{
          position: 'fixed',
          bottom: '88px',
          right: '24px',
        }}
        size="small"
        color="secondary"
        onClick={handleOnUpload}
      >
        <UploadIcon />
      </Fab>
      <Fab
        style={{
          position: 'fixed',
          bottom: '24px',
          right: '24px',
        }}
        variant="extended"
        size="large"
        color="primary"
        onClick={handleGenerateConfig}
      >
        Generate Config
      </Fab>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleFileChanged}
      />
    </div>
  );
}
